import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  OrderApiResponse,
  PostOrderPaymentAPIRequest,
} from "../../pos/order/models";
import {
  CalculateRefundableItemsRequest,
  CalculateRefundableItemsResponse,
  GetAllCustomerDebtReportRequest,
  GetAllCustomerDebtReportResponse,
  GetCustomerDebtReportRequest,
  GetCustomerDebtReportResponse,
  GetCustomerSummaryDownloadUrlAPIRequest,
  GetCustomerSummaryDownloadUrlAPIResponse,
  GetPaymentReportRequest,
  GetPaymentReportResponse,
  GetRefundableItemsRequest,
  GetRefundableItemsResponse,
  GetRefundReceiptRequest,
  GetRefundReceiptResponse,
  RefundRequest,
  RefundResponse,
  SendRefundReceiptRequest,
  SendRefundReceiptResponse,
} from "./models";

const CustDebtReportApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  tagTypes: ["CustomerDebts", "PaymentReport"],
  reducerPath: "customer-debt-rtkq",
  endpoints: (builder) => ({
    getPaymentReport: builder.query<
      GetPaymentReportResponse,
      GetPaymentReportRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_PAYMENT_REPORT,
        queryParams: params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ orderId }) => ({
                type: "PaymentReport" as const,
                id: orderId,
              })),
              "PaymentReport",
            ]
          : ["PaymentReport"],
    }),
    getCustomerDebtReport: builder.query<
      GetCustomerDebtReportResponse,
      GetCustomerDebtReportRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_ORDER_REPORT,
        queryParams: params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ orderId }) => ({
                type: "CustomerDebts" as const,
                id: orderId,
              })),
              "CustomerDebts",
            ]
          : ["CustomerDebts"],
    }),
    payOffCustomerDebt: builder.mutation<
      OrderApiResponse,
      PostOrderPaymentAPIRequest
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.ORDER_PAYMENT,
        data: payload,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: "CustomerDebts", id: result.data.id },
              { type: "PaymentReport", id: result.data.id },
            ]
          : [],
    }),
    getRefundableItems: builder.mutation<
      GetRefundableItemsResponse,
      GetRefundableItemsRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.REFUNDABLE_ITEMS,
        queryParams: args,
      }),
    }),
    calculateRefundableItems: builder.mutation<
      CalculateRefundableItemsResponse,
      CalculateRefundableItemsRequest
    >({
      query: (args) => ({
        method: "POST",
        url: Endpoints.CALCULATE_REFUND,
        data: args,
      }),
    }),
    refund: builder.mutation<RefundResponse, RefundRequest>({
      query: (args) => ({
        method: "POST",
        url: Endpoints.REFUND,
        data: args,
      }),
    }),
    getRefundReceipt: builder.query<
      GetRefundReceiptResponse,
      GetRefundReceiptRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.REFUND_RECEIPT,
        routeParams: {
          refundId: args.refundId,
        },
      }),
    }),
    sendRefundReceipt: builder.mutation<
      SendRefundReceiptResponse,
      SendRefundReceiptRequest
    >({
      query: (args) => ({
        method: "POST",
        url: Endpoints.SEND_REFUND_RECEIPT,
        data: args,
      }),
    }),
    getCustomerSummaryDownloadUrl: builder.query<
      GetCustomerSummaryDownloadUrlAPIResponse,
      GetCustomerSummaryDownloadUrlAPIRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_CUSTOMER_SUMMARY_DOWNLOAD_URL,
        routeParams: {
          storeId: args.storeId,
        },
      }),
    }),
    getAllCustomerDebtReport: builder.query<
      GetAllCustomerDebtReportResponse,
      GetAllCustomerDebtReportRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_ORDER_REPORT,
        queryParams: params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ orderId }) => ({
                type: "CustomerDebts" as const,
                id: orderId,
              })),
              "CustomerDebts",
            ]
          : ["CustomerDebts"],
    }),
  }),
});

export const {
  useGetCustomerDebtReportQuery,
  useGetPaymentReportQuery,
  useLazyGetPaymentReportQuery,
  useLazyGetCustomerDebtReportQuery,
  usePayOffCustomerDebtMutation,
  useGetRefundableItemsMutation,
  useCalculateRefundableItemsMutation,
  useRefundMutation,
  useGetRefundReceiptQuery,
  useSendRefundReceiptMutation,
  useLazyGetCustomerSummaryDownloadUrlQuery,
  useGetAllCustomerDebtReportQuery,
  useLazyGetAllCustomerDebtReportQuery,
} = CustDebtReportApi;

export default CustDebtReportApi;
