import { createApi } from "@reduxjs/toolkit/query/react";
import { GlobalReportAPIRequest } from "..";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  GetSalesReportAPIResponse,
  GetSalesReportResponseDetail,
  GetSalesTrendReportAPIResponse,
} from "./models";

const SalesReportApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  reducerPath: "sales-report-rtkq",
  tagTypes: ["SalesReport", "SalesTrendReport"],
  endpoints: (builder) => ({
    getSalesReport: builder.query<
      GetSalesReportResponseDetail,
      GlobalReportAPIRequest
    >({
      query: (params) => ({
        url: Endpoints.GET_SALES_REPORT,
        method: "GET",
        queryParams: params,
      }),
      providesTags: ["SalesReport"],
      transformResponse: (response: GetSalesReportAPIResponse) => response.data,
    }),
    getSalesTrendReport: builder.query<
      GetSalesTrendReportAPIResponse,
      GlobalReportAPIRequest
    >({
      query: (params) => ({
        url: Endpoints.GET_SALES_TREND_REPORT,
        method: "GET",
        queryParams: params,
      }),
      providesTags: ["SalesTrendReport"],
    }),
    downloadSalesReoprt: builder.query<string, GlobalReportAPIRequest>({
      query: (params) => ({
        method: "GET",
        queryParams: params,
        url: Endpoints.DOWNLOAD_SALES_REPORT,
      }),
      transformResponse: (response: { data: { url: string } }) =>
        response.data.url,
    }),
  }),
});

export const {
  useGetSalesReportQuery,
  endpoints: SalesEndpoints,
  useGetSalesTrendReportQuery,
} = SalesReportApi;

export default SalesReportApi;
