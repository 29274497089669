import { AnyAction } from "@reduxjs/toolkit";
import { actionTypes } from "../../actionTypes";
import { Todo } from "./model";
import { TodoStateType } from "./types";

const initialState = [] as Array<Todo>;

export default function todoReducer(
  state: TodoStateType = initialState,
  action: AnyAction
): TodoStateType {
  switch (action.type) {
    case actionTypes.TODO_ADD: {
      return [
        ...state,
        {
          id: state.reduce((maxId, todo) => Math.max(todo.id, maxId), -1) + 1,
          isFinished: false,
          text: action.text,
        },
      ];
    }

    case actionTypes.TODO_DELETE: {
      return state.filter((todo) => todo.id !== action.id);
    }

    case actionTypes.TODO_SET: {
      return [...action.payload];
    }

    default:
      return state;
  }
}
