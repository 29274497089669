import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  GetSalesPerCategoryAPIRequest,
  GetSalesPerCategoryAPIResponse,
} from "./models";

const SalesCategoryApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  reducerPath: "sales-category-rtkq",
  tagTypes: ["SalesCategoryReport"],
  endpoints: (builder) => ({
    getSalesPerCategoryReport: builder.query<
      GetSalesPerCategoryAPIResponse,
      GetSalesPerCategoryAPIRequest
    >({
      query: (params) => ({
        method: "GET",
        queryParams: params,
        url: Endpoints.GET_SALES_PER_CATEGORY_REPORT,
      }),
      providesTags: ["SalesCategoryReport"],
    }),
    downloadSalesPerCategoryReport: builder.query<
      string,
      GetSalesPerCategoryAPIRequest
    >({
      query: (params) => ({
        method: "GET",
        queryParams: params,
        url: Endpoints.DOWNLOAD_SALES_PER_CATEGORY_REPORT,
      }),
      transformResponse: (response: { data: { url: string } }) =>
        response.data.url,
    }),
  }),
});

export const {
  endpoints: SalesCategoryEndpoints,
  useGetSalesPerCategoryReportQuery,
  useLazyGetSalesPerCategoryReportQuery,
} = SalesCategoryApi;

export default SalesCategoryApi;
