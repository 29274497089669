import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import { iGetFiltersListResponse } from "./models";
import { iInventoryListItem } from "../../inventory/common/models";
import {
  iGetInventoryListResponse,
  iGetInventoryListRequest,
} from "../../inventory/list";
import {
  iGetFiltersListRequest,
  iGetInventoryByBarcodeRequest,
  iGetInventoryByKeywordRequest,
  iGetProductsAndInventoriesRequest,
  iGetProductsByInventoryIdsRequest,
  iGetInventoryByBarcodeResponse,
  GetInventoryRecommendationRankingAPIResponse,
  GetInventoryRecommendationRankingAPIRequest,
} from "./types";
import { iApiResponse, iListApiResponse } from "../../common/models";
import { inventoryListApiBasePayload } from "./constants";
import { OfflineMetaData } from "../../inventory/offline";

const POSInventoriesApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  tagTypes: ["POS_Inventories", "InventoryRecommendationRanking"],
  reducerPath: "pos_inventories",
  endpoints: (builder) => ({
    getFiltersList: builder.query<
      iGetFiltersListResponse,
      iGetFiltersListRequest
    >({
      query: ({
        level = 1,
        storeId,
        page = 0,
        pageSize = 500,
        hasInventoryOnly,
      }) => ({
        method: "POST",
        url: Endpoints.GET_PRODUCT_CATEGORY_LIST,
        data: {
          level,
          storeId,
          page,
          pageSize,
          hasInventoryOnly,
        },
      }),
    }),
    getInventoriesList: builder.query<
      iGetInventoryListResponse,
      iGetInventoryListRequest
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.GET_INVENTORY_LIST,
        data: { ...inventoryListApiBasePayload, ...payload },
      }),
      extraOptions: {
        withOffline: true,
      },
    }),

    getInventoryByBarcode: builder.query<
      iGetInventoryByBarcodeResponse,
      iGetInventoryByBarcodeRequest
    >({
      query: ({ barcode, storeId }) => ({
        method: "POST",
        url: Endpoints.GET_INVENTORY_LIST,
        data: {
          ...inventoryListApiBasePayload,
          page: 1,
          pageSize: 100,
          barcode,
          keyword: null,
          storeId,
        },
      }),
      extraOptions: {
        withOffline: true,
      },
      providesTags: ["POS_Inventories"],
    }),

    getInventoryByKeyword: builder.mutation<
      iInventoryListItem[],
      iGetInventoryByKeywordRequest
    >({
      query: ({ keyword, storeId }) => ({
        method: "POST",
        url: Endpoints.GET_INVENTORY_LIST,
        data: {
          ...inventoryListApiBasePayload,
          page: 1,
          pageSize: 100,
          barcode: null,
          keyword,
          storeId,
        },
      }),
      extraOptions: {
        withOffline: true,
      },
      transformResponse: (response: { data: iInventoryListItem[] }) =>
        response.data,
    }),

    getProductsAndInventoriesById: builder.mutation<
      iListApiResponse<iInventoryListItem[]>,
      iGetProductsAndInventoriesRequest
    >({
      query: (data) => ({
        method: "POST",
        url: Endpoints.GET_INVENTORY_LIST,
        data: { ...inventoryListApiBasePayload, ...data },
      }),
      extraOptions: {
        withOffline: true,
      },
    }),

    getProductsByInventoryIds: builder.mutation<
      iListApiResponse<iInventoryListItem[]>,
      iGetProductsByInventoryIdsRequest
    >({
      query: (data) => ({
        method: "POST",
        url: Endpoints.GET_INVENTORY_LIST,
        data: { ...inventoryListApiBasePayload, ...data },
      }),
      extraOptions: {
        withOffline: true,
      },
    }),

    getInventoryLastUpdateTime: builder.query<
      iApiResponse<OfflineMetaData>,
      { storeId: string }
    >({
      query: ({ storeId }) => ({
        method: "GET",
        url: Endpoints.GET_OFFLINE_META_DATA,
        forceOffline: true,
        queryParams: {
          storeId,
        },
      }),
      extraOptions: {
        withOffline: true,
      },
    }),

    getInventoryRecommendationRanking: builder.query<
      GetInventoryRecommendationRankingAPIResponse,
      GetInventoryRecommendationRankingAPIRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.INVENTORY_RECOMMENDATION_RANKING,
        queryParams: params,
      }),
      providesTags: ["InventoryRecommendationRanking"],
    }),
  }),
});

export const {
  internalActions,
  useLazyGetInventoriesListQuery,
  useGetInventoryByBarcodeQuery,
  useLazyGetInventoryByBarcodeQuery,
  useLazyGetFiltersListQuery,
  useGetInventoryByKeywordMutation,
  useGetProductsAndInventoriesByIdMutation,
  useGetProductsByInventoryIdsMutation,
  useGetInventoryLastUpdateTimeQuery,
  useLazyGetInventoryLastUpdateTimeQuery,
  useLazyGetInventoryRecommendationRankingQuery,
} = POSInventoriesApi;

export const { getInventoriesList } = POSInventoriesApi.endpoints;

export default POSInventoriesApi;
