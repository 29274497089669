import { createApi } from "@reduxjs/toolkit/query/react";
import { GlobalReportAPIRequest } from "..";
import axiosBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  GetExpenseReportResponse,
  iAddExpenseAPIRequest,
  iAddExpenseAPIResponse,
  iAddExpenseCategoryAPIRequest,
  iAddExpenseCategoryAPIResponse,
  iDeleteExpenseAPIRequest,
  iDownloadExpenseReportAPIResponse,
  iGetExpenseAPIRequest,
  iGetExpenseAPIResponse,
  iGetStoreExpenseCategoriesAPIRequest,
  iGetStoreExpenseCategoriesAPIResponse,
  iUpdateExpenseAPIRequest,
} from "./models";

const ExpenseApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "expense-report-rtk",
  tagTypes: ["ExpenseReport"],
  endpoints: (builder) => ({
    getExpenseReport: builder.query<
      GetExpenseReportResponse,
      GlobalReportAPIRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.STORE_EXPENSE_SUMMARY,
        queryParams: args,
      }),
      providesTags: ["ExpenseReport"],
    }),
    getExpensesList: builder.mutation<
      iGetExpenseAPIResponse,
      iGetExpenseAPIRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.STORE_EXPENSE,
        queryParams: payload,
      }),
    }),
    downloadExpenseReport: builder.query<
      iDownloadExpenseReportAPIResponse,
      iGetExpenseAPIRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.DOWNLOAD_STORE_EXPENSE_REPORT,
        queryParams: payload,
      }),
    }),
    addExpense: builder.mutation<iAddExpenseAPIResponse, iAddExpenseAPIRequest>(
      {
        query: (data) => ({
          method: "POST",
          url: Endpoints.STORE_EXPENSE,
          data,
        }),
      }
    ),
    updateExpense: builder.mutation<
      iAddExpenseAPIResponse,
      iUpdateExpenseAPIRequest
    >({
      query: (data) => ({
        method: "PATCH",
        url: Endpoints.STORE_EXPENSE,
        data,
      }),
      invalidatesTags: ["ExpenseReport"],
    }),
    deleteExpense: builder.mutation<void, iDeleteExpenseAPIRequest>({
      query: (data) => ({
        method: "DELETE",
        url: Endpoints.STORE_EXPENSE_BY_ID,
        routeParams: { ...data },
      }),
    }),
    getStoreExpenseCategories: builder.mutation<
      iGetStoreExpenseCategoriesAPIResponse,
      iGetStoreExpenseCategoriesAPIRequest
    >({
      query: (data) => ({
        method: "GET",
        url: Endpoints.STORE_EXPENSE_CATEGORY,
        queryParams: data,
      }),
    }),
    addExpenseCategory: builder.mutation<
      iAddExpenseCategoryAPIResponse,
      iAddExpenseCategoryAPIRequest
    >({
      query: (data) => ({
        method: "POST",
        url: Endpoints.STORE_EXPENSE_CATEGORY,
        data,
      }),
    }),
  }),
});

export const {
  useGetExpenseReportQuery,
  useGetExpensesListMutation,
  useLazyDownloadExpenseReportQuery,
  useAddExpenseMutation,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
  useGetStoreExpenseCategoriesMutation,
  useAddExpenseCategoryMutation,
} = ExpenseApi;

export default ExpenseApi;
