import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";

import {
  iPostInventoryChangeStatusRequest,
  iPostInventoryChangeStatusResponse,
} from "./types";

const InventoryChangeStatusApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  reducerPath: "change-status",
  endpoints: (builder) => ({
    // post request
    postInventoryChangeStatus: builder.mutation<
      iPostInventoryChangeStatusResponse,
      iPostInventoryChangeStatusRequest
    >({
      query: (data) => ({
        method: "post",
        url: Endpoints.UPDATE_INVENTORY_STATUS,
        data: data,
      }),
    }),
  }),
});

export const {
  usePostInventoryChangeStatusMutation,
} = InventoryChangeStatusApi;

export default InventoryChangeStatusApi;
