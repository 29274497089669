import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  GetSellInMPRecommendationListAPIRequest,
  GetSellInMPRecommendationListAPIResponse,
  GetSellInMPRecommendationRequest,
  GetSellInMPRecommendationResponse,
} from "./models";

import {
  iGetInventoryListRequest,
  iGetInventoryListResponse,
  PrincipalAPIRequest,
  PrincipalAPIResponse,
  ProductUnitAPIRequest,
  ProductUnitAPIResponse,
  SearchInventoryAPIRequest,
  SearchInventoryAPIResponse,
  GetS3InventoryListDownloadURLAPIRequest,
  GetS3InventoryListDownloadURLAPIResponse,
  GetProductProposalListAPIRequest,
  GetProductProposalListAPIResponse,
  GetInventorySummaryAPIRequest,
  GetInventorySummaryAPIResponse,
  GetInventoryMostPurchasedListAPIResponse,
  GetInventoryMostPurchasedListAPIRequest,
} from "./types";

const InventoryListApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  reducerPath: "inventory",
  tagTypes: ["InventoryList", "ProductUnits", "Principals"],
  endpoints: (builder) => ({
    getListInventory: builder.query<
      iGetInventoryListResponse,
      iGetInventoryListRequest
    >({
      query: (data) => ({
        method: "post",
        url: Endpoints.GET_INVENTORY_LIST,
        data: data,
      }),
      providesTags: (result, error) => {
        if (error) {
          return [];
        }

        if (result) {
          return ["InventoryList"];
        }

        return [];
      },
      extraOptions: { withOffline: true },
    }),
    getPrincipal: builder.query<PrincipalAPIResponse, PrincipalAPIRequest>({
      query: (args) => ({
        method: "get",
        url: Endpoints.GET_PRINCIPAL,
        queryParams: args,
      }),
      providesTags: () => ["Principals"],
    }),
    getProductUnitList: builder.query<
      ProductUnitAPIResponse,
      ProductUnitAPIRequest
    >({
      query: (data) => ({
        method: "get",
        url: Endpoints.GET_PRODUCT_UNIT,
        queryParams: data,
      }),
      providesTags: ["ProductUnits"],
    }),
    searchInventory: builder.query<
      SearchInventoryAPIResponse,
      SearchInventoryAPIRequest
    >({
      query: (data) => ({
        method: "get",
        url: Endpoints.SEARCH_INVENTORY,
        queryParams: data,
      }),
    }),
    getInventoryDownloadUrl: builder.query<
      GetS3InventoryListDownloadURLAPIResponse,
      GetS3InventoryListDownloadURLAPIRequest
    >({
      query: (args) => ({
        method: "get",
        url: Endpoints.GET_DOWNLOAD_INVENTORY_LIST_S3,
        routeParams: {
          storeId: args.storeId,
        },
      }),
      transformResponse: (res: {
        data: GetS3InventoryListDownloadURLAPIResponse;
      }) => res.data,
    }),
    getProductProposalList: builder.query<
      GetProductProposalListAPIResponse,
      GetProductProposalListAPIRequest
    >({
      query: (args) => ({
        method: "get",
        url: Endpoints.GET_PRODUCT_PROPOSAL,
        queryParams: args,
      }),
    }),
    getInventorySummary: builder.query<
      GetInventorySummaryAPIResponse,
      GetInventorySummaryAPIRequest
    >({
      query: (args) => ({
        method: "get",
        url: Endpoints.INVENTORY_SUMMARY,
        queryParams: args,
      }),
    }),
    getInventoryMostPurchasedList: builder.query<
      GetInventoryMostPurchasedListAPIResponse,
      GetInventoryMostPurchasedListAPIRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.INVENTORY_MOST_PURCHASED,
        queryParams: args,
      }),
    }),
    getSellInMPRecommendationList: builder.query<
      GetSellInMPRecommendationListAPIResponse,
      GetSellInMPRecommendationListAPIRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.SELL_IN_MP_RECOMMENDATION_LIST,
        routeParams: { storeId: args.storeId },
        queryParams: {
          minData: args.minData,
          maxData: args.maxData,
        },
      }),
    }),
    getSellInMPRecommendation: builder.query<
      GetSellInMPRecommendationResponse,
      GetSellInMPRecommendationRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.SELL_IN_MP_RECOMMENDATION,
        routeParams: {
          storeId: args.storeId,
          productVariantId: args.productVariantId.toString(),
        },
      }),
    }),
  }),
});

export const {
  useGetPrincipalQuery,
  useLazyGetPrincipalQuery,
  useGetProductUnitListQuery,
  useGetListInventoryQuery,
  useLazyGetListInventoryQuery,
  useLazySearchInventoryQuery,
  useSearchInventoryQuery,
  useLazyGetInventoryDownloadUrlQuery,
  useGetProductProposalListQuery,
  useLazyGetInventorySummaryQuery,
  useGetInventoryMostPurchasedListQuery,
  useGetSellInMPRecommendationListQuery,
  useLazyGetSellInMPRecommendationListQuery,
  useGetSellInMPRecommendationQuery,
} = InventoryListApi;

export default InventoryListApi;
