import { createApi } from "@reduxjs/toolkit/query/react";
import { ProfitPerProductResponse } from ".";
import axiosBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import { GlobalReportAPIRequest } from "../types";
import {
  GetProfitPerProductDetailRequest,
  GetProfitPerProductDetailResponse,
  GetProfitPerProductRequest,
  GetProfitPerProductResponse,
  GetProfitReportResponse,
  GetProfitReportResponseData,
  GetProfitTrendReportResponse,
} from "./models";

const ProfitReportApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "profit-report-rtk",
  tagTypes: ["ProfitReport", "ProfitTrendReport", "ProfitPerProduct"],
  endpoints: (builder) => ({
    getProfitReport: builder.query<
      GetProfitReportResponseData,
      GlobalReportAPIRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_PROFIT_REPORT,
        queryParams: args,
      }),
      providesTags: ["ProfitReport"],
      transformResponse: (response: GetProfitReportResponse) => response.data,
    }),
    getProfitPerProductReport: builder.query<
      ProfitPerProductResponse,
      GetProfitPerProductRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_PROFIT_PER_PRODUCT_REPORT,
        queryParams: params,
      }),
      providesTags: ["ProfitPerProduct"],
      transformResponse: ({ data, ...rest }: GetProfitPerProductResponse) => ({
        listProfitPerProduct: data,
        ...rest,
      }),
    }),
    getProfitPerProductDetail: builder.query<
      GetProfitPerProductDetailResponse,
      GetProfitPerProductDetailRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_PROFIT_PER_PRODUCT_DETAIL,
        queryParams: params,
      }),
    }),
    downloadProfitPerProduct: builder.mutation<
      string,
      GetProfitPerProductRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.DOWNLOAD_PROFIT_PER_PRODUCT_REPORT,
        queryParams: params,
      }),
      transformResponse: (response: { data: { url: string } }) =>
        response.data.url,
    }),
    downloadProfit: builder.mutation<string, GlobalReportAPIRequest>({
      query: (params) => ({
        method: "GET",
        url: Endpoints.DOWNLOAD_PROFIT_REPORT,
        queryParams: params,
      }),
      transformResponse: (response: { data: { url: string } }) =>
        response.data.url,
    }),
    getProfitTrendReport: builder.query<
      GetProfitTrendReportResponse,
      GlobalReportAPIRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_PROFIT_TREND_REPORT,
        queryParams: params,
      }),
      providesTags: ["ProfitTrendReport"],
    }),
  }),
});

export const {
  useGetProfitPerProductReportQuery,
  useDownloadProfitPerProductMutation,
  useDownloadProfitMutation,
  useGetProfitTrendReportQuery,
  useGetProfitPerProductDetailQuery,
  useLazyGetProfitPerProductReportQuery,
  useLazyGetProfitReportQuery,
} = ProfitReportApi;

export default ProfitReportApi;
