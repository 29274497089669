import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  iGETBadStockReportBrandsListRequest,
  iGETBadStockReportBrandsListResponse,
  iGETBadStockReportListRequest,
  iGETBadStockReportListResponse,
} from "./models";

const InventoryBadStockApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  reducerPath: "inventory-bad-stock-report-query",
  endpoints: (builder) => ({
    getBadStockReportList: builder.query<
      iGETBadStockReportListResponse,
      iGETBadStockReportListRequest
    >({
      query: (queryParams) => ({
        method: "GET",
        url: Endpoints.GET_INVENTORY_BAD_STOCK_REPORT_LIST,
        queryParams: queryParams,
      }),
    }),
    getBadStockReportBrandsList: builder.query<
      iGETBadStockReportBrandsListResponse,
      iGETBadStockReportBrandsListRequest
    >({
      query: (queryParams) => ({
        method: "GET",
        url: Endpoints.GET_INVENTORY_BAD_STOCK_REPORT_BRANDS_LIST,
        queryParams: queryParams,
      }),
    }),
  }),
});

export const {
  useGetBadStockReportListQuery,
  useLazyGetBadStockReportListQuery,
  useGetBadStockReportBrandsListQuery,
  useLazyGetBadStockReportBrandsListQuery,
} = InventoryBadStockApi;

export default InventoryBadStockApi;
