import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import INITIAL_STATE from "./initalState";
import { Article } from "./model";

const articleSlice = createSlice({
  name: "article",
  initialState: INITIAL_STATE,
  reducers: {
    addArticle(state, action: PayloadAction<Article>) {
      state.articles.push(action.payload);
    },
    removeArticle(state, action) {
      state.articles = state.articles.filter(
        (article) => article.id !== action.payload.id
      );
    },
  },
});

export const { addArticle, removeArticle } = articleSlice.actions;

export default articleSlice.reducer;
