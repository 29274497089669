import { createApi } from "@reduxjs/toolkit/query/react";
import { GlobalReportAPIRequest } from "..";
import axiosBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import { GetDebtReceivablesResponse } from "./models";

const DebtReceivablesApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "debt-receivables-rtk",
  tagTypes: ["DebtReceivables"],
  endpoints: (builder) => ({
    getDebtAndReceivables: builder.query<
      GetDebtReceivablesResponse,
      GlobalReportAPIRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_DEBT_RECEIVABLES,
        queryParams: args,
      }),
      providesTags: ["DebtReceivables"],
    }),
  }),
});

export const { useGetDebtAndReceivablesQuery } = DebtReceivablesApi;

export default DebtReceivablesApi;
