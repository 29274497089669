import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import * as localforage from "localforage";

// Apis
import TodoApi from "./example/todo-rtk-query/hooks";
import CartApi from "./pos/cart/hooks";
import POSInventoriesApi from "./pos/inventories/hooks";
import CustomerApi from "./crm/hooks";
import OrderApi from "./pos/order/hooks";
import EmployeeApi from "./employee-access/hooks";
import InventoryListApi from "./inventory/list/hooks";
import OtpApi from "./auth/otp/hooks";
import UserApi from "./user/hooks";
import RegistrationApi from "./auth/registration/hooks";
import CustDebtReportApi from "./report/customer-debt/hooks";
import CuratedProductsApi from "./inventory/common/apis/InventoryApi";
import StoreApi from "./stores/hooks";
import SalesCategoryApi from "./report/sales-category/hooks";
import SalesProductApi from "./report/sales-product/hooks";
import SalesApi from "./report/sales/hooks";
import ProfitReportApi from "./report/profit/hooks";
import DebtReceivablesApi from "./report/debt-receivables/hooks";
import ExpenseApi from "./report/expense/hooks";
import InventoryBulkEditApi from "./inventory/bulk-edit/hooks";
import ConsignmentDebtApi from "./report/consignment-debt/hooks";
import BuyingApi from "./inventory/buying/hooks";
import SupplierApi from "./supplier/hooks";
import DeliveryOrderApi from "./report/delivery-order/common/hooks";
import TaxApi from "./report/tax/hooks";
import StockOpnameApi from "./stock-opname/hooks";
import InventoryHistoryApi from "./inventory/history/hooks";
import OfflineTransactionApi from "./report/offline-transaction/hooks";
import InventoryChangeStatusApi from "./inventory/change-status/hooks";
import InventoryBadStockApi from "./inventory/bad-stock-report/hooks";
import CoachMarkApi from "./coach-mark/hooks";
import { promotionApi } from "./promotion";
import NotificationsApi from "./notifications/hooks";

// Reducers
import ArticleReducer from "./example/article/slice";
import InventoryListReducer from "./inventory/list/slice";
import CrmReducer from "./crm/slice";
import PaymentReducer from "./pos/payment/slice";
import CuratedInventoryReducer from "./inventory/common/slices/InventoryFormSlice";
import BuyingSlice from "./inventory/buying/slice";
import PosReducer from "./pos";
import GadaOfflineReducer from "./offline/slice";
import InventoryHistoryReducer from "./inventory/history/slice";

import todoReducer from "./example/todo-plain/reducers";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import GlobalReportReducer from "./report/slice";
import CustDebtReducer from "./report/customer-debt/slice";
import SalesCategoryReducer from "./report/sales-category/slice";
import PricingEditorSlice from "./inventory/pricing/slice";
import SyncStoresReducer from "./sync-stores/slice";
import {
  SideMenuSliceName,
  SideMenuSliceReducer,
  UserMenuSliceName,
  UserMenuSliceReducer,
} from "./user/menus/slice";
import CachedUserSlice from "./user/slice";
import SalesPerProductReducer from "./report/sales-product/slice";
import ProfitPerProductSlice from "./report/profit/slice";
import StoreReducer from "./stores/slice";
import SupplierReducer from "./supplier/slice";
import InventoryConversionReducer from "./inventory/hierarchy/slice";
import DeliveryOrderListSlice from "./report/delivery-order/list/slice";
import SelectInvoiceSlice from "./report/delivery-order/select-invoice/slice";
import DeliveryOrderReducer from "./report/delivery-order/delivery-order/slice";
import RefundReducer from "./report/refund/slice";
import StockOpnameSlice from "./stock-opname/slice";
import SupplierListSlice from "./supplier/list/slice";
import StoreDebtReducer from "./report/store-debt/slice";
import InventoryValueSlice from "./report/inventory-value/slice";
import { ChangeInventoryStatusSlice } from "./inventory/change-status/slice";
import { BadStockReportSlice } from "./inventory/bad-stock-report/slice";
import { HandleBadStockUIStateSlice } from "./inventory/handle-bad-stock";
import InventoryHandleBadStockApi from "./inventory/handle-bad-stock/hooks";
import CoachMarkReducer from "./coach-mark/slice";
import { BulkAddInventoryState } from "./inventory/bulk-add/slice";
import BulkAddInventoryApi from "./inventory/bulk-add/hooks";
import { promotionListSlice, createPromotionUISlice } from "./promotion";
import ClosedBannersSlice from "./notifications/closed-banners/slice";
import { CachedInventoryRecommendationRankingSlice } from "./pos/inventories/slice";

// Configurations
export const localForageStorage = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: "gada-persist",
  storeName: "gada-storage",
});

const persistConfig = {
  key: "root",
  version: 1,
  storage: localForageStorage,
  whitelist: [
    UserMenuSliceName,
    CachedUserSlice.name,
    BulkAddInventoryState.name,
    ClosedBannersSlice.name,
    CachedInventoryRecommendationRankingSlice.name,
  ],
};

const RootReducer = combineReducers({
  // ---- Reducers ----
  article: ArticleReducer,
  todo: todoReducer,
  inventoryList: InventoryListReducer,
  crm: CrmReducer,
  payment: PaymentReducer,
  customerDebt: CustDebtReducer,
  globalReport: GlobalReportReducer,
  curatedProducts: CuratedInventoryReducer,
  salesCategory: SalesCategoryReducer,
  syncStores: SyncStoresReducer,
  pos: PosReducer,
  salesPerProduct: SalesPerProductReducer,
  gadaOffline: GadaOfflineReducer,
  storeReducer: StoreReducer,
  deliveryOrder: DeliveryOrderReducer,
  refund: RefundReducer,
  stockOpname: StockOpnameSlice,
  inventoryHistory: InventoryHistoryReducer,
  storeDebt: StoreDebtReducer,
  coachMark: CoachMarkReducer,
  [ClosedBannersSlice.name]: ClosedBannersSlice.reducer,
  [CachedUserSlice.name]: CachedUserSlice.reducer,
  [UserMenuSliceName]: UserMenuSliceReducer,
  [SideMenuSliceName]: SideMenuSliceReducer,
  [SupplierReducer.name]: SupplierReducer.reducer,
  [InventoryConversionReducer.name]: InventoryConversionReducer.reducer,
  [ProfitPerProductSlice.name]: ProfitPerProductSlice.reducer,
  [BuyingSlice.name]: BuyingSlice.reducer,
  [DeliveryOrderListSlice.name]: DeliveryOrderListSlice.reducer,
  [SelectInvoiceSlice.name]: SelectInvoiceSlice.reducer,
  [PricingEditorSlice.name]: PricingEditorSlice.reducer,
  [SupplierListSlice.name]: SupplierListSlice.reducer,
  [InventoryValueSlice.name]: InventoryValueSlice.reducer,
  [ChangeInventoryStatusSlice.name]: ChangeInventoryStatusSlice.reducer,
  [BadStockReportSlice.name]: BadStockReportSlice.reducer,
  [HandleBadStockUIStateSlice.name]: HandleBadStockUIStateSlice.reducer,
  [BulkAddInventoryState.name]: BulkAddInventoryState.reducer,
  [promotionListSlice.name]: promotionListSlice.reducer,
  [createPromotionUISlice.name]: createPromotionUISlice.reducer,
  [CachedInventoryRecommendationRankingSlice.name]:
    CachedInventoryRecommendationRankingSlice.reducer,
  // ---- APIs ----
  [TodoApi.reducerPath]: TodoApi.reducer,
  [CartApi.reducerPath]: CartApi.reducer,
  [POSInventoriesApi.reducerPath]: POSInventoriesApi.reducer,
  [CustomerApi.reducerPath]: CustomerApi.reducer,
  [OrderApi.reducerPath]: OrderApi.reducer,
  [EmployeeApi.reducerPath]: EmployeeApi.reducer,
  [InventoryListApi.reducerPath]: InventoryListApi.reducer,
  [OtpApi.reducerPath]: OtpApi.reducer,
  [UserApi.reducerPath]: UserApi.reducer,
  [RegistrationApi.reducerPath]: RegistrationApi.reducer,
  [CustDebtReportApi.reducerPath]: CustDebtReportApi.reducer,
  [CuratedProductsApi.reducerPath]: CuratedProductsApi.reducer,
  [StoreApi.reducerPath]: StoreApi.reducer,
  [SalesCategoryApi.reducerPath]: SalesCategoryApi.reducer,
  [SalesApi.reducerPath]: SalesApi.reducer,
  [SalesProductApi.reducerPath]: SalesProductApi.reducer,
  [ProfitReportApi.reducerPath]: ProfitReportApi.reducer,
  [DebtReceivablesApi.reducerPath]: DebtReceivablesApi.reducer,
  [ExpenseApi.reducerPath]: ExpenseApi.reducer,
  [InventoryBulkEditApi.reducerPath]: InventoryBulkEditApi.reducer,
  [ConsignmentDebtApi.reducerPath]: ConsignmentDebtApi.reducer,
  [BuyingApi.reducerPath]: BuyingApi.reducer,
  [SupplierApi.reducerPath]: SupplierApi.reducer,
  [DeliveryOrderApi.reducerPath]: DeliveryOrderApi.reducer,
  [TaxApi.reducerPath]: TaxApi.reducer,
  [StockOpnameApi.reducerPath]: StockOpnameApi.reducer,
  [InventoryHistoryApi.reducerPath]: InventoryHistoryApi.reducer,
  [OfflineTransactionApi.reducerPath]: OfflineTransactionApi.reducer,
  [InventoryChangeStatusApi.reducerPath]: InventoryChangeStatusApi.reducer,
  [InventoryBadStockApi.reducerPath]: InventoryBadStockApi.reducer,
  [InventoryHandleBadStockApi.reducerPath]: InventoryHandleBadStockApi.reducer,
  [CoachMarkApi.reducerPath]: CoachMarkApi.reducer,
  [BulkAddInventoryApi.reducerPath]: BulkAddInventoryApi.reducer,
  [NotificationsApi.reducerPath]: NotificationsApi.reducer,
  [promotionApi.reducerPath]: promotionApi.reducer,
});

const store = configureStore({
  reducer: persistReducer(persistConfig, RootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      TodoApi.middleware,
      CartApi.middleware,
      POSInventoriesApi.middleware,
      CustomerApi.middleware,
      OrderApi.middleware,
      EmployeeApi.middleware,
      OtpApi.middleware,
      UserApi.middleware,
      RegistrationApi.middleware,
      InventoryListApi.middleware,
      CustDebtReportApi.middleware,
      CuratedProductsApi.middleware,
      StoreApi.middleware,
      SalesCategoryApi.middleware,
      SalesApi.middleware,
      SalesProductApi.middleware,
      ProfitReportApi.middleware,
      DebtReceivablesApi.middleware,
      ExpenseApi.middleware,
      InventoryBulkEditApi.middleware,
      ConsignmentDebtApi.middleware,
      BuyingApi.middleware,
      SupplierApi.middleware,
      DeliveryOrderApi.middleware,
      TaxApi.middleware,
      StockOpnameApi.middleware,
      InventoryHistoryApi.middleware,
      OfflineTransactionApi.middleware,
      InventoryChangeStatusApi.middleware,
      InventoryBadStockApi.middleware,
      InventoryHandleBadStockApi.middleware,
      CoachMarkApi.middleware,
      BulkAddInventoryApi.middleware,
      NotificationsApi.middleware,
      promotionApi.middleware,
    ]),
  devTools: true,
  // devTools: process.env.NODE_ENV !== "production",
});

export default store;

export const { dispatch: appDispatch } = store;
export type AppStateType = ReturnType<typeof RootReducer>;
export type AppDispatch = typeof appDispatch;
export const useThunkDispatch = (): ThunkDispatch<
  AppDispatch,
  null,
  AnyAction
> => useDispatch();

export const persistor = persistStore(store);
export const useTypedSelector: TypedUseSelectorHook<AppStateType> = useSelector;
