import { createApi } from "@reduxjs/toolkit/query/react";
import { Endpoints } from "../../common/api";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import {
  PostAddBulkAPIRequestInterface,
  PostAddBulkAPIResponseInterface,
} from "./types";

const BulkAddInventoryApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  reducerPath: "inventory-bulk-add",
  endpoints: (builder) => ({
    postBulkAdd: builder.mutation<
      PostAddBulkAPIResponseInterface,
      PostAddBulkAPIRequestInterface
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.BULK_ADD,
        data: args,
      }),
    }),
  }),
});

export const { usePostBulkAddMutation } = BulkAddInventoryApi;

export default BulkAddInventoryApi;
