import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, Endpoints } from "../common/api";
import {
  GetNotificationBannerRequest,
  GetNotificationBannerResponse,
} from "./models";

const NotificationsApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "notifications-rtk-query",
  endpoints: (build) => ({
    getNotificationBanner: build.query<
      GetNotificationBannerResponse,
      GetNotificationBannerRequest
    >({
      query: () => ({
        method: "GET",
        url: Endpoints.NOTIFICATION_BANNER,
      }),
    }),
  }),
});

export const { useGetNotificationBannerQuery } = NotificationsApi;

export default NotificationsApi;
