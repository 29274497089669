import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, Endpoints } from "../common/api";
import {
  CreatePromotionAPIRequest,
  CreatePromotionAPIResponse,
  DeletePromotionAPIRequest,
  DownloadPromotionDataAPIRequest,
  DownloadPromotionDataAPIResponse,
  GetPromotionDataAPIRequest,
  GetPromotionDataAPIResponse,
  GetPromotionDetailAPIRequest,
  GetPromotionDetailAPIResponse,
  GetPromotionListAPIRequest,
  GetPromotionListAPIResponse,
  PromotionInventoriesSearchAPIRequest,
  PromotionInventoriesSearchAPIResponse,
  UpdatePromotionAPIRequest,
  UpdatePromotionAPIResponse,
} from "./types";

export const promotionApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "promotion-rtkq",
  tagTypes: ["Promotion"],
  endpoints: (builder) => ({
    getPromotionList: builder.query<
      GetPromotionListAPIResponse,
      GetPromotionListAPIRequest
    >({
      query: (params) => ({
        method: "GET",
        queryParams: params,
        url: Endpoints.PROMOTION,
      }),
      providesTags: (res) =>
        res
          ? res.data.map(({ id }) => ({
              type: "Promotion" as const,
              id,
            }))
          : [{ type: "Promotion" as const, id: "LIST" }],
    }),
    getPromotionData: builder.query<
      GetPromotionDataAPIResponse,
      GetPromotionDataAPIRequest
    >({
      query: (params) => ({
        method: "GET",
        queryParams: params,
        url: Endpoints.PROMOTION_DATA,
      }),
    }),
    getPromotionDetail: builder.query<
      GetPromotionDetailAPIResponse,
      GetPromotionDetailAPIRequest
    >({
      query: ({ promotionId, storeId }) => ({
        method: "GET",
        url: Endpoints.PROMOTION_BY_ID,
        routeParams: { promotionId },
        queryParams: { storeId },
      }),
      providesTags: (res) => {
        return res ? [{ type: "Promotion" as const, id: res.data.id }] : [];
      },
    }),
    downloadPromotionData: builder.mutation<
      DownloadPromotionDataAPIResponse,
      DownloadPromotionDataAPIRequest
    >({
      query: (params) => ({
        method: "GET",
        queryParams: params,
        url: Endpoints.PROMOTION_DATA_DOWNLOAD,
      }),
    }),
    promotionInventoriesSearch: builder.query<
      PromotionInventoriesSearchAPIResponse,
      PromotionInventoriesSearchAPIRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.PROMOTION_INVENTORIES_SEARCH,
        queryParams: params,
      }),
    }),
    createPromotion: builder.mutation<
      CreatePromotionAPIResponse,
      CreatePromotionAPIRequest
    >({
      query: (data) => ({
        method: "POST",
        url: Endpoints.PROMOTION,
        data,
      }),
      invalidatesTags: (res) =>
        res ? [{ type: "Promotion" as const, id: res.data.id }] : [],
    }),
    updatePromotion: builder.mutation<
      UpdatePromotionAPIResponse,
      UpdatePromotionAPIRequest & { shouldInvalidateTags?: boolean }
    >({
      query: ({ payload, routeParams }) => ({
        method: "PATCH",
        url: Endpoints.PROMOTION_BY_ID,
        routeParams,
        data: payload,
      }),
      invalidatesTags: (res, _err, args) => {
        if (args.shouldInvalidateTags) {
          return res
            ? [{ type: "Promotion" as const, id: args.routeParams.promotionId }]
            : [];
        }
        return [];
      },
    }),
    deletePromotion: builder.mutation<unknown, DeletePromotionAPIRequest>({
      query: (params) => ({
        method: "DELETE",
        url: Endpoints.PROMOTION_BY_ID,
        routeParams: {
          promotionId: params.promotionId,
        },
        queryParams: {
          storeId: params.storeId,
        },
      }),
    }),
    stopPromotion: builder.mutation<unknown, DeletePromotionAPIRequest>({
      query: ({ promotionId, storeId }) => ({
        method: "PATCH",
        url: Endpoints.STOP_PROMOTION,
        routeParams: { promotionId },
        queryParams: { storeId },
      }),
      invalidatesTags: (_, err, args) =>
        !err ? [{ type: "Promotion" as const, id: args.promotionId }] : [],
    }),
  }),
});

export const {
  useGetPromotionListQuery,
  useGetPromotionDataQuery,
  useGetPromotionDetailQuery,
  useLazyGetPromotionDetailQuery,
  useDownloadPromotionDataMutation,
  usePromotionInventoriesSearchQuery,
  useCreatePromotionMutation,
  useUpdatePromotionMutation,
  useDeletePromotionMutation,
  useStopPromotionMutation,
  useLazyPromotionInventoriesSearchQuery,
} = promotionApi;

export default promotionApi;
