import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  GetOfflineTransactionReportResponse,
  GetOfflineTransactionReportRequest,
  GetOfflineTransactionByIdResponse,
  GetOfflineTransactionByIdRequest,
} from "./models";

const OfflineTransactionsAPI = createApi({
  baseQuery: apiSauceBaseQuery(),
  tagTypes: ["OfflineTransactions"],
  reducerPath: "offline-transactions-rtkq",
  endpoints: (builder) => ({
    getOfflineTransactions: builder.query<
      GetOfflineTransactionReportResponse,
      GetOfflineTransactionReportRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_OFFLINE_PAYMENT_REPORT,
        queryParams: params,
        forceOffline: true,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    getOfflineTransactionsById: builder.query<
      GetOfflineTransactionByIdResponse,
      GetOfflineTransactionByIdRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_OFFLINE_PAYMENT_REPORT_BY_ID,
        routeParams: {
          requestQueueId: params.requestQueueId,
        },
        forceOffline: true,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
  }),
});

export const {
  useGetOfflineTransactionsQuery,
  useGetOfflineTransactionsByIdQuery,
} = OfflineTransactionsAPI;
export default OfflineTransactionsAPI;
