import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { Endpoints } from "../../common/api";
import axiosBaseQuery from "../../common/api/apiSauceBaseQuery";
import {
  GetIncomingTaxRequest,
  GetIncomingTaxResponse,
  GetOutgoingTaxRequest,
  GetOutgoingTaxResponse,
} from "./models";

const TaxApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "tax-rtk",
  tagTypes: ["Tax"],
  endpoints: (builder) => ({
    getIncomingTax: builder.query<
      GetIncomingTaxResponse,
      GetIncomingTaxRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.INCOMING_TAX_REPORT,
        queryParams: args,
      }),
    }),
    getOutgoingTax: builder.query<
      GetOutgoingTaxResponse,
      GetOutgoingTaxRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.OUTGOING_TAX_REPORT,
        queryParams: args,
      }),
    }),
  }),
});

export const {
  useLazyGetIncomingTaxQuery,
  useLazyGetOutgoingTaxQuery,
} = TaxApi;

export default TaxApi;
