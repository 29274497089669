import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import { Todo } from "./models";
import { convertToServerFormat } from "./utils";

const TodoApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "todo-rtk-query",
  tagTypes: ["Todos"],
  endpoints: (build) => ({
    getAllTodo: build.query<Todo[], void>({
      query: () => ({
        method: "get",
        url: Endpoints.GET_ALL_TODO,
      }),
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: "Todos", id })) : ["Todos"],
    }),
    addTodo: build.mutation<void, string>({
      query: (text) => ({
        method: "post",
        url: Endpoints.ADD_TODO,
        data: convertToServerFormat(text),
      }),
      invalidatesTags: ["Todos"],
    }),
    deleteTodo: build.mutation<void, number>({
      query: (id) => ({
        method: "delete",
        url: Endpoints.DELETE_TODO,
        routeParams: {
          id: String(id),
        },
      }),
      invalidatesTags: ["Todos"],
    }),
  }),
});

export const {
  useGetAllTodoQuery,
  useAddTodoMutation,
  useDeleteTodoMutation,
} = TodoApi;
export default TodoApi;
