import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../common/api/apiSauceBaseQuery";
import { Endpoints } from "../common/api/endpoints";
import {
  AddEmployeeRequest,
  AddEmployeeResponse,
  DeleteEmployeeRequest,
  DeleteEmployeeResponse,
  EditEmployeeRequest,
  EditEmployeeResponse,
  EmployeePositions,
  GetAllEmployeesRequest,
  GetAllPermissionsRequest,
  GetAllPermissionsResponse,
  GetAllPositionsResponse,
  GetEmployeeDetailsRequest,
  GetEmployeeDetailsResponse,
  GetEmployeesResponse,
  GetTransactionEmployeesRequest,
  GetTransactionEmployeesResponse,
} from "./models";
import { PositionModel } from "./types";
import { transformPositionsToPositionModels } from "./utils";
import { WithOfflineFlag } from "../offline/types";

const EmployeeApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "employee-rtk-query",
  tagTypes: ["Employee", "Permission", "Position", "TransactionEmployee"],
  endpoints: (build) => ({
    getAllEmployees: build.query<GetEmployeesResponse, GetAllEmployeesRequest>({
      query: (args) => ({
        method: "GET",
        url: Endpoints.EMPLOYEE,
        queryParams: args,
      }),
      providesTags: ["Employee"],
    }),
    getEmployeeDetails: build.query<
      GetEmployeeDetailsResponse,
      GetEmployeeDetailsRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.DELETE_EMPLOYEE,
        routeParams: { ...args },
      }),
      providesTags: ["Employee"],
    }),
    editEmployee: build.mutation<EditEmployeeResponse, EditEmployeeRequest>({
      query: (args) => ({
        method: "PATCH",
        url: Endpoints.UPDATE_EMPLOYEE,
        data: {
          employeeName: args.employeeName,
          employeeEmail: args.employeeEmail,
          employeePhoneNumber: args.employeePhoneNumber,
          storeId: args.storeId,
          position: args.position,
          permissionIdList: args.permissionIdList,
        },
        routeParams: {
          employeeId: args.employeeId,
        },
      }),
      invalidatesTags: ["Employee", "TransactionEmployee"],
    }),
    addEmployee: build.mutation<AddEmployeeResponse, AddEmployeeRequest>({
      query: (args) => ({
        method: "POST",
        url: Endpoints.EMPLOYEE,
        data: args,
      }),
      invalidatesTags: ["Employee", "TransactionEmployee"],
    }),
    deleteEmployee: build.mutation<
      DeleteEmployeeResponse,
      DeleteEmployeeRequest
    >({
      query: (args) => ({
        method: "DELETE",
        url: Endpoints.DELETE_EMPLOYEE,
        routeParams: {
          storeId: args.storeId,
          employeeId: args.employeeId,
        },
      }),
      invalidatesTags: ["Employee", "TransactionEmployee"],
    }),
    getAllPermissions: build.query<
      GetAllPermissionsResponse,
      GetAllPermissionsRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_ALL_ACCESS_RIGHTS,
        queryParams: args,
      }),
      providesTags: ["Permission"],
    }),
    getAllPostions: build.query<PositionModel[], void>({
      query: () => ({
        method: "GET",
        url: Endpoints.GET_ALL_POSITIONS,
      }),
      providesTags: ["Position"],
      transformResponse: (response: GetAllPositionsResponse) =>
        transformPositionsToPositionModels(response),
    }),
    getEmployeeByPosition: build.mutation<
      GetEmployeesResponse,
      GetAllEmployeesRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.EMPLOYEE,
        queryParams: payload,
      }),
    }),
    getSalesEmployees: build.query<
      GetEmployeesResponse,
      WithOfflineFlag<GetAllEmployeesRequest>
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.EMPLOYEE,
        queryParams: { ...args, position: EmployeePositions.SALES },
      }),
      providesTags: ["Employee"],
      extraOptions: {
        withOffline: true,
      },
    }),
    getCashierEmployees: build.query<
      GetEmployeesResponse,
      GetAllEmployeesRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.EMPLOYEE,
        queryParams: { ...args, position: EmployeePositions.KASIR },
      }),
      providesTags: ["Employee"],
    }),
    getTransactionEmployees: build.query<
      GetTransactionEmployeesResponse,
      GetTransactionEmployeesRequest
    >({
      query: ({ queryParams, routeParams }) => ({
        method: "GET",
        url: Endpoints.TRANSACTION_EMPLYEE_LIST,
        queryParams,
        routeParams,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: "TransactionEmployee" as const,
                id,
              })),
              "TransactionEmployee",
            ]
          : ["TransactionEmployee"],
    }),
  }),
});

export const {
  useGetAllEmployeesQuery,
  useGetEmployeeDetailsQuery,
  useEditEmployeeMutation,
  useAddEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetAllPermissionsQuery,
  useGetAllPostionsQuery,
  useGetEmployeeByPositionMutation,
  useGetSalesEmployeesQuery,
  useGetCashierEmployeesQuery,
  useLazyGetSalesEmployeesQuery,
  useLazyGetCashierEmployeesQuery,
  useGetTransactionEmployeesQuery,
  useLazyGetTransactionEmployeesQuery,
} = EmployeeApi;

export default EmployeeApi;
