import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  RegisterAccountResponse,
  RegisterExistingAccountRequest,
  RegisterNewAccountRequest,
} from "./models";

const RegistrationApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "registration-rtk-query",
  endpoints: (build) => ({
    registerNewAccount: build.mutation<
      RegisterAccountResponse,
      RegisterNewAccountRequest
    >({
      query: (args) => ({
        method: "POST",
        url: Endpoints.REGISTER_NEW_ACCOUNT,
        data: args,
      }),
    }),
    registerExistingAccount: build.mutation<
      RegisterAccountResponse,
      RegisterExistingAccountRequest
    >({
      query: (args) => ({
        method: "POST",
        url: Endpoints.REGISTER_EXISTING_ACCOUNT,
        data: args,
      }),
    }),
  }),
});

export const {
  useRegisterNewAccountMutation,
  useRegisterExistingAccountMutation,
} = RegistrationApi;

export default RegistrationApi;
