import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import omit from "lodash/omit";

import {
  GetConsignorItemsAPIRequest,
  GetConsignorItemsAPIResponse,
  CreateBuyingAPIRequest,
  CreateBuyingAPIResponse,
  PostPurchasePaymentAPIResponse,
  PostPurchasePaymentAPIRequest,
} from "./types";
import {
  iGetStorePurchaseListAPIResponse,
  iGetStorePurchaseListAPIRequest,
  iGetPurchaseDetailAPIRequest,
  iGetPurchaseDetailAPIResponse,
  iDownloadPurchaseReportAPIResponse,
} from "./models";

const BuyingAPI = createApi({
  baseQuery: apiSauceBaseQuery(),
  reducerPath: "buyingAPI",
  endpoints: (builder) => ({
    getSupplierItems: builder.query<
      GetConsignorItemsAPIResponse,
      GetConsignorItemsAPIRequest
    >({
      query: (args) => ({
        method: "get",
        url: Endpoints.GET_CONSIGNOR_ITEMS,
        routeParams: {
          consignorId: args.consignorId,
        },
        queryParams: omit(args, "consignorId"),
      }),
    }),
    createPurchase: builder.mutation<
      CreateBuyingAPIResponse,
      CreateBuyingAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.STORE_PURCHASE,
        data: args,
      }),
    }),
    getPurchaseList: builder.mutation<
      iGetStorePurchaseListAPIResponse,
      iGetStorePurchaseListAPIRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.STORE_PURCHASE,
        queryParams: args,
      }),
    }),
    getPurchaseDetail: builder.mutation<
      iGetPurchaseDetailAPIResponse,
      iGetPurchaseDetailAPIRequest
    >({
      query: ({ storeId, id }) => ({
        method: "GET",
        url: Endpoints.STORE_PURCHASE_DETAIL,
        routeParams: { id },
        queryParams: { storeId },
      }),
    }),
    downloadPurchaseReport: builder.mutation<
      iDownloadPurchaseReportAPIResponse,
      iGetStorePurchaseListAPIRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.STORE_PURCHASE_DOWNLOAD,
        queryParams: args,
      }),
    }),
    postPurchasePayment: builder.mutation<
      PostPurchasePaymentAPIResponse,
      PostPurchasePaymentAPIRequest
    >({
      query: (args) => ({
        method: "POST",
        url: Endpoints.STORE_PURCHASE_PAYMENT,
        data: args,
      }),
    }),
  }),
});

export const {
  useCreatePurchaseMutation,
  useGetSupplierItemsQuery,
  useLazyGetSupplierItemsQuery,
  useGetPurchaseListMutation,
  useGetPurchaseDetailMutation,
  useDownloadPurchaseReportMutation,
  usePostPurchasePaymentMutation,
} = BuyingAPI;

export default BuyingAPI;
