import { createApi } from "@reduxjs/toolkit/query/react";
import {
  PayOffDebtRequest,
  PayOffDebtResponse,
} from ".";
import axiosBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  GetCreditReportRequest,
  GetCreditReportResponse,
  GetDebitReportRequest,
  GetDebitReportResponse,
  GetReportDetailsRequest,
  GetReportDetailsResponse,
} from "./models";

const ConsignmentDebtApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "consignment-debt-report-rtk",
  endpoints: (builder) => ({
    getCreditReport: builder.mutation<
      GetCreditReportResponse,
      GetCreditReportRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_STORE_CREDIT,
        queryParams: args,
      }),
    }),
    getDebitReport: builder.mutation<
      GetDebitReportResponse,
      GetDebitReportRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_STORE_DEBIT,
        queryParams: args,
      }),
    }),
    getReportDetails: builder.mutation<
      GetReportDetailsResponse,
      GetReportDetailsRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_STORE_CREDIT_DETAILS,
        routeParams: {
          creditId: args.creditId,
        },
      }),
    }),
    payOffDebt: builder.mutation<PayOffDebtResponse, PayOffDebtRequest>({
      query: (args) => ({
        method: "POST",
        url: Endpoints.PAY_OFF_DEBT,
        data: args,
      }),
    }),
  }),
});

export const {
  useGetCreditReportMutation,
  useGetDebitReportMutation,
  useGetReportDetailsMutation,
  usePayOffDebtMutation,
} = ConsignmentDebtApi;

export default ConsignmentDebtApi;
