/* eslint-disable indent */
import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../../common/api/endpoints";
import omit from "lodash/omit";
// import { request } from "../../../common/api/request";
import { create } from "apisauce";
import {
  GetUOMAPIRequest,
  GetProductUnitAPIRequest,
  GetProductUnitAPIResponse,
  GetUOMAPIResponse,
  AddUOMAPIResponse,
  AddUOMAPIRequest,
  AddConversionAPIResponse,
  AddConversionAPIRequest,
  CuratedProductDetailAPIResponse,
  CuratedProductDetailAPIRequest,
  UpdateDisplayNameAPIRequest,
  UpdateDisplayNameAPIResponse,
  UpdateAddStockApiRequest,
  UpdateAddStockAPIResponse,
  UpdateRecountStockAPIRequest,
  UpdateRecountStockAPIResponse,
  UpdatePriceTierAPIRequest,
  UpdatePriceTierAPIResponse,
  UpdateBarcodeAPIRequest,
  UpdateBarcodeAPIResponse,
  UpdateStockReminderAPIRequest,
  UpdateStockReminderAPIResponse,
  UpdateConsignorAPIRequest,
  UpdateConsignorAPIResponse,
  GetProductCustomInformationAPIRequest,
  GetProductCustomInformationAPIResponse,
  CreateCuratedProductAPIResponse,
  CreateCuratedProductAPIRequest,
  CreateCustomProductAPIRequest,
  CreateCustomProductAPIResponse,
  GetBrandListAPIRequest,
  GetBrandListAPIResponse,
  CreateBrandAPIRequest,
  CreateBrandAPIResponse,
  CreateProductCategoryAPIRequest,
  CreateProductCategoryAPIResponse,
  GetProductCategoryListAPIRequest,
  GetProductCategoryListAPIResponse,
  GetPrincipalAPIResponse,
  GetPrincipalAPIRequest,
  CreatePrincipalAPIResponse,
  CreatePrincipalAPIRequest,
  UpdateProductInformationAPIResponse,
  UpdateProductInformationAPIRequest,
  UpdateProductDisplayAPIResponse,
  UpdateProductDisplayAPIRequest,
  GetProductPhotoPresignedAPIResponse,
  GetProductPhotoPresignedAPIRequest,
  // TODO : Add upload to s3 endpoint
  UploadProductPhotoToS3APIResponse,
  UploadProductPhotoToS3APIRequest,
  DeleteProductUnitAPIRequest,
  DeleteProductUnitAPIResponse,
  DeleteProductVariantAPIRequest,
  DeleteProductVariantAPIResponse,
  CreateSingleInventoryAPIRequest,
  CreateSingleInventoryAPIResponse,
  CreateProductProposalAPIRequest,
  CreateProductProposalAPIResponse,
  AdjustStockRequest,
  AdjustStockResponse,
  UpdateOnlineSellingInfoAPIRequest,
  UpdateOnlineSellingInfoAPIResponse,
  InventoryConversionValidationResponse,
  InventoryConversionValidationRequest,
} from "./types";
import { sortProductUnitHierarchy } from "../utils/InventoryFormUtils";
import { iApiResponse } from "../../../common/models";
import { iBaseQueryErrorResponse } from "../../../common/api";

type SAASAPIWrapper<T> = { data: T };

const AddProductAPI = createApi({
  reducerPath: "inventory-rtk-query",
  baseQuery: apiSauceBaseQuery(),
  tagTypes: ["Uoms", "ProductUnits", "Inventories", "InventoryList"],
  endpoints: (builder) => ({
    getProductUnit: builder.query<
      GetProductUnitAPIResponse,
      GetProductUnitAPIRequest
    >({
      query: (data) => ({
        method: "get",
        url: Endpoints.GET_PRODUCT_UNIT,
        queryParams: data,
      }),
      providesTags: (result) =>
        result
          ? result.data.map(({ productVariantId }) => ({
              type: "ProductUnits",
              id: productVariantId,
            }))
          : [{ type: "ProductUnits", id: "LIST" }],
      transformResponse: (response: GetProductUnitAPIResponse) => {
        return {
          ...response,
          data: sortProductUnitHierarchy(response.data).map((resp, idx) => ({
            ...resp,
            priority: idx + 1, // for indexing, think like priority queue
          })),
        };
      },
    }),
    getUOM: builder.query<GetUOMAPIResponse, GetUOMAPIRequest>({
      query: (data) => ({
        method: "get",
        url: Endpoints.GET_UOM,
        queryParams: data,
      }),
      providesTags: (result) =>
        result
          ? result.data.map(({ id }) => ({
              type: "Uoms",
              id,
            }))
          : [{ type: "Uoms", id: "LIST" }],
      transformResponse: (response: GetUOMAPIResponse) => {
        return {
          ...response,
          data: response.data.map((d) => ({
            ...d,
            priority: 9999, // for indexing, think like priority queue
          })),
        };
      },
    }),
    addUOM: builder.mutation<AddUOMAPIResponse, AddUOMAPIRequest>({
      query: (data) => ({
        method: "post",
        url: Endpoints.ADD_UOM,
        data,
      }),
      invalidatesTags: ["ProductUnits", "Uoms"],
    }),
    upsertConversion: builder.mutation<
      AddConversionAPIResponse,
      AddConversionAPIRequest
    >({
      query: (data) => ({
        method: "post",
        url: Endpoints.ADD_CONVERSION,
        data,
      }),
      invalidatesTags: ["ProductUnits"],
    }),
    getCuratedProductDetail: builder.query<
      CuratedProductDetailAPIResponse,
      CuratedProductDetailAPIRequest
    >({
      query: (data) => ({
        method: "get",
        url: Endpoints.GET_INVENTORY_DETAIL,
        routeParams: {
          variantId: data.variantId,
          storeId: data.storeId,
        },
      }),
      transformResponse: (
        resp: SAASAPIWrapper<CuratedProductDetailAPIResponse>
      ) => resp.data,
      providesTags: (result, _error, arg) => {
        if (result) {
          return [
            { type: "Inventories", id: `${arg.storeId}_${arg.variantId}` },
          ];
        }

        return [];
      },
    }),
    getProductCustomInformation: builder.query<
      GetProductCustomInformationAPIResponse,
      GetProductCustomInformationAPIRequest
    >({
      query: (data) => ({
        method: "post",
        url: Endpoints.GET_PRODUCT_CUSTOM_INFORMATION,
        data,
      }),
      transformResponse: (
        resp: SAASAPIWrapper<GetProductCustomInformationAPIResponse>
      ) => resp.data,
    }),
    updateDisplayName: builder.mutation<
      UpdateDisplayNameAPIResponse,
      UpdateDisplayNameAPIRequest
    >({
      query: (args) => ({
        method: "patch",
        url: Endpoints.UPDATE_INVENTORY_DISPLAY_NAME,
        routeParams: {
          variantId: args.variantId,
        },
        data: omit(args, ["variantId"]),
      }),
      invalidatesTags: ["Inventories"],
    }),
    topupStock: builder.mutation<
      UpdateAddStockAPIResponse,
      UpdateAddStockApiRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.UPDATE_INVENTORY_STOCK_TOPUP,
        data: args,
      }),
      invalidatesTags: ["Inventories"],
    }),
    recountStock: builder.mutation<
      UpdateRecountStockAPIResponse,
      UpdateRecountStockAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.UPDATE_INVENTORY_STOCK_RECOUNT,
        data: args,
      }),
      invalidatesTags: ["Inventories"],
    }),
    adjustStock: builder.mutation<AdjustStockResponse, AdjustStockRequest>({
      query: (args) => ({
        method: "post",
        url: Endpoints.ADJUST_INVENTORY_STOCK,
        data: args,
      }),
      invalidatesTags: ["Inventories"],
    }),
    updatePriceTiers: builder.mutation<
      UpdatePriceTierAPIResponse,
      UpdatePriceTierAPIRequest
    >({
      query: (args) => ({
        method: "put",
        url: Endpoints.UPDATE_INVENTORY_PRICE_TIERS,
        data: args,
      }),
      invalidatesTags: ["Inventories"],
    }),
    createBarcode: builder.mutation<
      UpdateBarcodeAPIResponse,
      UpdateBarcodeAPIRequest
    >({
      query: (args) => ({
        method: "POST",
        url: Endpoints.UPDATE_INVENTORY_BARCODE,
        data: args,
      }),
    }),
    updateBarcode: builder.mutation<
      UpdateBarcodeAPIResponse,
      UpdateBarcodeAPIRequest
    >({
      query: (args) => ({
        method: "put",
        url: Endpoints.UPDATE_INVENTORY_BARCODE,
        data: args,
      }),
      invalidatesTags: ["Inventories"],
    }),
    updateStockReminder: builder.mutation<
      UpdateStockReminderAPIResponse,
      UpdateStockReminderAPIRequest
    >({
      query: (args) => ({
        method: "patch",
        url: Endpoints.UPDATE_INVENTORY_STOCK_REMINDER,
        routeParams: {
          variantId: args.variantId,
        },
        data: omit(args, ["variantId"]),
      }),
      invalidatesTags: ["Inventories"],
    }),
    updateInventoryConsignor: builder.mutation<
      UpdateConsignorAPIResponse,
      UpdateConsignorAPIRequest
    >({
      query: (args) => ({
        method: "patch",
        url: Endpoints.UPDATE_INVENTORY_CONSIGNOR,
        routeParams: {
          variantId: args.variantId,
        },
        data: omit(args, ["variantId"]),
      }),
      invalidatesTags: ["Inventories"],
    }),
    updateOnlineSelling: builder.mutation<
      UpdateOnlineSellingInfoAPIResponse,
      UpdateOnlineSellingInfoAPIRequest
    >({
      query: (args) => ({
        method: "patch",
        url: Endpoints.UPDATE_INVENTORY_ONLINE_SELLING,
        routeParams: {
          inventoryId: args.inventoryId,
        },
        data: omit(args, ["inventoryId"]),
      }),
      invalidatesTags: ["Inventories"],
    }),
    updateCustomProductInformation: builder.mutation<
      UpdateProductInformationAPIResponse,
      UpdateProductInformationAPIRequest
    >({
      query: (args) => ({
        method: "patch",
        url: Endpoints.UPDATE_INVENTORY_CUSTOM_PRODUCT_INFO,
        routeParams: {
          variantId: args.variantId,
        },
        data: omit(args, ["variantId"]),
      }),
      invalidatesTags: ["Inventories"],
    }),
    updateCustomProductDisplay: builder.mutation<
      UpdateProductDisplayAPIResponse,
      UpdateProductDisplayAPIRequest
    >({
      query: (args) => ({
        method: "patch",
        url: Endpoints.UPDATE_INVENTORY_CUSTOM_PRODUCT_DISPLAY,
        routeParams: {
          variantId: args.variantId,
        },
        data: omit(args, ["variantId"]),
      }),
      invalidatesTags: ["Inventories"],
    }),
    getPresignedURL: builder.mutation<
      GetProductPhotoPresignedAPIResponse,
      GetProductPhotoPresignedAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.GET_PRODUCT_PHOTO_PRESIGNED_URL,
        data: args,
      }),
      transformResponse: (
        res: SAASAPIWrapper<GetProductPhotoPresignedAPIResponse>
      ) => res.data,
    }),
    uploadToS3: builder.mutation<
      UploadProductPhotoToS3APIResponse,
      UploadProductPhotoToS3APIRequest
    >({
      queryFn: (args) => {
        const apiSauce = create({ baseURL: args.url });

        return apiSauce
          .put(args.url, args.data, {
            headers: {
              "Content-Type": args.contentType,
            },
          })
          .then((res) => {
            if (!res.ok) {
              return {
                error: (res.data as unknown) as iBaseQueryErrorResponse, // TODO: Fawwaz, we need to fix this properly
              };
            } else {
              return {
                data: res.data as UploadProductPhotoToS3APIResponse,
              };
            }
          });
      },
    }),
    createCuratedProduct: builder.mutation<
      CreateCuratedProductAPIResponse,
      CreateCuratedProductAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.ADD_CURATED_INVENTORY,
        data: args,
      }),
      invalidatesTags: ["InventoryList", "ProductUnits"],
    }),
    createCustomProduct: builder.mutation<
      CreateCustomProductAPIResponse,
      CreateCustomProductAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.ADD_CUSTOM_INVENTORY,
        data: args,
      }),
      invalidatesTags: ["InventoryList", "ProductUnits"],
    }),
    getBrand: builder.query<GetBrandListAPIResponse, GetBrandListAPIRequest>({
      query: (args) => ({
        method: "get",
        url: Endpoints.GET_PRODUCT_BRAND,
        queryParams: args,
      }),
    }),
    createBrand: builder.mutation<
      CreateBrandAPIResponse,
      CreateBrandAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.ADD_PRODUCT_BRAND,
        data: args,
      }),
      transformResponse: (resp: SAASAPIWrapper<CreateBrandAPIResponse>) =>
        resp.data,
    }),
    getProductCategory: builder.query<
      GetProductCategoryListAPIResponse,
      GetProductCategoryListAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.GET_PRODUCT_CATEGORY_LIST,
        data: args,
      }),
    }),
    createProductCategory: builder.mutation<
      CreateProductCategoryAPIResponse,
      CreateProductCategoryAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.ADD_PRODUCT_CATEGORY,
        data: args,
      }),
    }),
    getPrincipal: builder.query<
      GetPrincipalAPIResponse,
      GetPrincipalAPIRequest
    >({
      query: (args) => ({
        method: "get",
        url: Endpoints.GET_PRINCIPAL,
        queryParams: args,
      }),
    }),
    createPrincipal: builder.mutation<
      CreatePrincipalAPIResponse,
      CreatePrincipalAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.CREATE_PRINCIPAL,
        data: args,
      }),
    }),
    deleteProductVariant: builder.mutation<
      DeleteProductVariantAPIResponse,
      DeleteProductVariantAPIRequest
    >({
      query: (args) => ({
        method: "delete",
        url: Endpoints.DELETE_INVENTORY_PRODUCT_VARIANT,
        routeParams: {
          variantId: String(args.variantId),
        },
        queryParams: args, 
      }),
      invalidatesTags: (result, _err, arg) => {
        if (result) {
          return [
            { type: "Inventories", id: `${arg.storeId}_${arg.variantId}` },
          ];
        }

        return [];
      },
    }),
    deleteProductUnit: builder.mutation<
      DeleteProductUnitAPIResponse,
      DeleteProductUnitAPIRequest
    >({
      query: (args) => ({
        method: "delete",
        url: Endpoints.DELETE_INVENTORY_PRODUCT_UNIT,
        data: args,
        queryParams: args,
      }),
      invalidatesTags: ["Inventories"],
    }),
    createSingleInventory: builder.mutation<
      CreateSingleInventoryAPIResponse,
      CreateSingleInventoryAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.CREATE_SINGLE_INVENTORY,
        data: args,
      }),
      transformResponse: (
        res: SAASAPIWrapper<CreateSingleInventoryAPIResponse>
      ) => res.data,
    }),
    createProductProposal: builder.mutation<
      CreateProductProposalAPIResponse,
      CreateProductProposalAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.CREATE_PRODUCT_PROPOSAL,
        data: args,
      }),
      transformResponse: (
        resp: iApiResponse<CreateProductProposalAPIResponse>
      ) => resp.data,
    }),
    inventoryConversionValidation: builder.mutation<
      InventoryConversionValidationResponse,
      InventoryConversionValidationRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.INVENTORY_CONVERSION_VALIDATION,
        data: args,
      }),
    }),
  }),
});

export const {
  useGetProductUnitQuery,
  useLazyGetProductUnitQuery,
  useLazyGetUOMQuery,
  useGetUOMQuery,
  useAddUOMMutation,
  useUpsertConversionMutation,
  useGetCuratedProductDetailQuery,
  useGetProductCustomInformationQuery,
  useUpdateDisplayNameMutation,
  useTopupStockMutation,
  useRecountStockMutation,
  useUpdatePriceTiersMutation,
  useUpdateBarcodeMutation,
  useCreateBarcodeMutation,
  useUpdateStockReminderMutation,
  useUpdateInventoryConsignorMutation,
  useUpdateOnlineSellingMutation,
  useCreateCuratedProductMutation,
  useCreateCustomProductMutation,
  useCreateBrandMutation,
  useGetBrandQuery,
  useLazyGetBrandQuery,
  useLazyGetProductCategoryQuery,
  useGetProductCategoryQuery,
  useCreateProductCategoryMutation,
  useGetPrincipalQuery,
  useLazyGetPrincipalQuery,
  useCreatePrincipalMutation,
  useUpdateCustomProductDisplayMutation,
  useUpdateCustomProductInformationMutation,
  useGetPresignedURLMutation,
  useUploadToS3Mutation,
  useDeleteProductUnitMutation,
  useDeleteProductVariantMutation,
  useCreateSingleInventoryMutation,
  useCreateProductProposalMutation,
  useAdjustStockMutation,
  useInventoryConversionValidationMutation,
} = AddProductAPI;

export default AddProductAPI;
