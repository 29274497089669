import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import { create } from "apisauce";
import {
  DownloadInventoryTemplateRequest,
  DownloadInventoryTemplateResponse,
  UploadURLTemplateApiRequest,
  UploadURLTemplateApiResponse,
} from "./types";
import { iBaseQueryErrorResponse } from "../../common/api";

const InventoryBulkEditApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  reducerPath: "inventory-bulk-edit-rtkq",
  endpoints: (builder) => ({
    downloadInventoryTemplate: builder.query<
      DownloadInventoryTemplateResponse,
      DownloadInventoryTemplateRequest
    >({
      query: ({ fileType, storeId }) => ({
        method: "GET",
        url: Endpoints.DOWNLOAD_INVENTORY_TEMPLATE,
        routeParams: {
          storeId,
        },
        queryParams: {
          fileType,
        },
      }),
    }),
    getInventoryTemplateUrl: builder.query<
      string,
      DownloadInventoryTemplateRequest
    >({
      query: ({ storeId, fileType }) => ({
        method: "GET",
        url: Endpoints.GET_INVENTORY_TEMPLATE_URL,
        routeParams: {
          storeId,
        },
        queryParams: {
          fileType,
        },
      }),
      transformResponse: (response: { data: { storageLink: string } }) =>
        response.data.storageLink,
    }),
    uploadUrlTemplate: builder.mutation<
      UploadURLTemplateApiResponse,
      UploadURLTemplateApiRequest
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.UPLOAD_INVENTORY_TEMPLATE,
        data: payload,
      }),
    }),
    uploadTemplateToS3: builder.mutation<
      unknown,
      { file: File; s3Url: string }
    >({
      queryFn: ({ file, s3Url }) => {
        const apiSauce = create({ baseURL: s3Url });
        const formData = new FormData();
        formData.append("Content-Type", file.type);
        formData.append("file", file);

        return apiSauce
          .put(s3Url, undefined, {
            headers: { "Content-Type": "" },
            data: file,
          })
          .then((res) => {
            if (!res.ok) {
              return {
                error: (res.data as unknown) as iBaseQueryErrorResponse,
              };
            } else {
              return {
                data: res.data,
              };
            }
          });
      },
    }),
  }),
});

export const {
  useUploadUrlTemplateMutation,
  useUploadTemplateToS3Mutation,
  useLazyDownloadInventoryTemplateQuery,
  useLazyGetInventoryTemplateUrlQuery,
} = InventoryBulkEditApi;

export default InventoryBulkEditApi;
