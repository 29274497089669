import { createApi } from "@reduxjs/toolkit/query/react";
import omit from "lodash/omit";
import { axiosBaseQuery, Endpoints } from "../common/api";
import { UpdateTutorialRequest, UpdateTutorialResponse } from "./models";
import { iGetAllTutorialsResponse } from "./types";
import { transformGetAllTutorialResponse } from "./util";

const TutorialApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "tutorial-rtk-query",
  tagTypes: ["Tutorial"],
  endpoints: (build) => ({
    getAllTutorials: build.query<iGetAllTutorialsResponse, void>({
      query: () => ({
        method: "GET",
        url: Endpoints.TUTORIAL,
      }),
      providesTags: ["Tutorial"],
      transformResponse: transformGetAllTutorialResponse,
    }),
    updateTutorial: build.mutation<
      UpdateTutorialResponse,
      UpdateTutorialRequest
    >({
      query: (args) => ({
        method: "PUT",
        url: Endpoints.TUTORIAL,
        data: omit(args, "skip"),
      }),
      invalidatesTags: (_, err, args) => {
        if (args.skip && !err) {
          return [];
        }
        return ["Tutorial"];
      },
    }),
  }),
});

export const {
  useGetAllTutorialsQuery,
  useUpdateTutorialMutation,
} = TutorialApi;

export default TutorialApi;
