import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import {
  iPostHandleBadStockResponse,
  iPostHandleBadStockRequest,
  iPostCalculateRefundCostResponse,
  iPostCalculateRefundCostRequest,
} from "./models";

const InventoryHandleBadStockApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  reducerPath: "handle-bad-stock",
  endpoints: (builder) => ({
    postHandleBadStock: builder.mutation<
      iPostHandleBadStockResponse,
      iPostHandleBadStockRequest
    >({
      query: (data) => ({
        method: "POST",
        url: Endpoints.POST_HANDLE_BAD_STOCK,
        data: data,
      }),
    }),
    postCalculateRefundCost: builder.mutation<
      iPostCalculateRefundCostResponse,
      iPostCalculateRefundCostRequest
    >({
      query: (data) => ({
        method: "POST",
        url: Endpoints.POST_CALCULATE_REFUND_COST,
        data: data,
      }),
    }),
  }),
});

export const {
  usePostHandleBadStockMutation,
  usePostCalculateRefundCostMutation,
} = InventoryHandleBadStockApi;

export default InventoryHandleBadStockApi;
