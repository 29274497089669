import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../common/api/apiSauceBaseQuery";
import { Endpoints } from "../common/api/endpoints";
import {
  GetCustomerSummariesRequest,
  CustomerSummary,
  GetCustomerSummariesAPIResponse,
  AddCustomerRequest,
  CustomerMutationResponse,
  UpdateCustomerArgs,
  DeleteCustomerRequest,
  GetCustomersRequest,
  GetCustomersResponse,
  CustomerDetail,
  DeleteCustomerResponse,
  GetCustomerSummaryResponse,
} from "./models";
import { WithOfflineFlag } from "../offline";

const CustomerApi = createApi({
  baseQuery: apiSauceBaseQuery(),
  tagTypes: ["Customers", "CustomerSummaries"],
  reducerPath: "customer",
  endpoints: (builder) => ({
    getCustomers: builder.query<GetCustomersResponse, GetCustomersRequest>({
      query: (params) => ({
        method: "GET",
        url: Endpoints.CUSTOMER,
        queryParams: params,
      }),
      providesTags: ["Customers"],
      extraOptions: {
        withOffline: true,
      },
    }),
    getCustomerSummaries: builder.query<
      { customerSummaries: CustomerSummary[]; total: number; page: number },
      GetCustomerSummariesRequest
    >({
      query: (params) => ({
        method: "GET",
        url: Endpoints.GET_ALL_CUSTOMER_SUMMARY,
        queryParams: params,
      }),
      transformResponse: (response: GetCustomerSummariesAPIResponse) => ({
        customerSummaries: response.data,
        page: response.page,
        total: response.total,
      }),
      providesTags: ["CustomerSummaries"],
    }),
    getCustomerDetail: builder.query<
      CustomerDetail,
      WithOfflineFlag<{ storeId: string; customerId: string }>
    >({
      query: ({ customerId, storeId }) => ({
        method: "GET",
        url: Endpoints.CUSTOMER_BY_ID_STORE_ID,
        routeParams: {
          customerId,
          storeId,
        },
      }),
      transformResponse: (response: { data: CustomerDetail }) => response.data,
      extraOptions: {
        withOffline: true,
      },
    }),
    getCustomerSummaryDetail: builder.query<
      GetCustomerSummaryResponse,
      WithOfflineFlag<{ storeId: string; customerId: string }>
    >({
      query: ({ customerId, storeId }) => ({
        method: "GET",
        url: Endpoints.GET_CUSTOMER_SUMMARY_BY_CUSTOMER_ID,
        routeParams: {
          customerId,
          storeId,
        },
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    addCustomer: builder.mutation<CustomerMutationResponse, AddCustomerRequest>(
      {
        query: ({ payload }) => ({
          method: "POST",
          url: Endpoints.CUSTOMER,
          data: payload,
        }),
        invalidatesTags: (_, err, args) => {
          if (args.shouldInvalidate && !err) {
            return ["CustomerSummaries", "Customers"];
          }
          return [];
        },
        extraOptions: {
          withOffline: true,
        },
      }
    ),
    editCustomer: builder.mutation<
      CustomerMutationResponse,
      UpdateCustomerArgs
    >({
      query: ({ payload, routeParams }) => ({
        method: "PATCH",
        url: Endpoints.CUSTOMER_BY_ID,
        routeParams,
        data: payload,
      }),
      invalidatesTags: (_, err, args) => {
        if (args.shouldInvalidate && !err) {
          return ["CustomerSummaries", "Customers"];
        }
        return [];
      },
    }),
    deleteCustomer: builder.mutation<
      DeleteCustomerResponse,
      DeleteCustomerRequest
    >({
      query: (args) => ({
        method: "DELETE",
        url: Endpoints.CUSTOMER_BY_ID_STORE_ID,
        routeParams: args,
      }),
      invalidatesTags: ["CustomerSummaries", "Customers"],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useGetCustomerSummariesQuery,
  useAddCustomerMutation,
  useEditCustomerMutation,
  useDeleteCustomerMutation,
  useGetCustomerDetailQuery,
  useLazyGetCustomersQuery,
  useLazyGetCustomerSummaryDetailQuery,
} = CustomerApi;

export default CustomerApi;
