import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../../common/api/apiSauceBaseQuery";
import { Endpoints } from "../../common/api/endpoints";
import { WithOfflineFlag } from "../../offline";
import {
  CreateCartAPIRequest,
  GetCartByIdAPIRequest,
  CartDiscountAPIRequest,
  CartSaveAPIRequest,
  iCart,
  CartAPIResponse,
  CartCheckoutAPIRequest,
  CartAddItemAPIRequest,
  CartAddCustomItemAPIRequest,
  CartCancelAPIResponse,
  CartClearItemAPIRequest,
  CartClearCustomItemAPIRequest,
  CartUpdateCustomItemAPIRequest,
  CartSavedListAPIRequest,
  CartSavedListAPIResponse,
  CartCancelBulkAPIRequest,
  UpdateCartDIscountAPIRequest,
  DeleteCartDiscountRequest,
  CartCheckoutAPIResponse,
  CartActivateAPIRequest,
  GetLocalCartAPIRequest,
  GetLocalCartAPIResponse,
  CreateDiscountItemAPIRequest,
  UpdateDiscountItemAPIRequest,
  DeleteDiscountItemAPIRequest,
  CreateBillDownloadUrlAPIRequest,
  CreateBillDownloadUrlAPIResponse,
  SendBillEmailAPIRequest,
  SendBillEmailAPIResponse,
  GetBillDownloadUrlAPIResponse,
  GetBillDownloadUrlAPIRequest,
} from "./models";
import omit from "lodash/omit";

const CartApi = createApi({
  // TODO: `baseQuery` should pass the base query for the specific api base path e.g. in this case /cart
  baseQuery: apiSauceBaseQuery(),
  tagTypes: ["Cart"],
  reducerPath: "pos_cart",
  endpoints: (builder) => ({
    createCart: builder.mutation<CartAPIResponse, CreateCartAPIRequest>({
      query: ({ storeId, cashierId }) => ({
        method: "POST",
        url: Endpoints.CART,
        data: {
          storeId: storeId,
          cashierId: cashierId,
        },
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    getCartById: builder.query<
      CartAPIResponse,
      WithOfflineFlag<GetCartByIdAPIRequest>
    >({
      query: ({ cartId, isOffline, isRefreshCalculation }) => ({
        method: "GET",
        url: Endpoints.GET_CART_BY_ID,
        routeParams: {
          cartId,
        },
        queryParams: {
          isRefreshCalculation: isRefreshCalculation || false,
        },
        forceOffline: isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
      providesTags: ["Cart"],
    }),
    getCartDetailById: builder.mutation<
      iCart,
      WithOfflineFlag<GetCartByIdAPIRequest>
    >({
      query: ({ cartId, isOffline }) => ({
        method: "GET",
        url: Endpoints.GET_CART_BY_ID,
        routeParams: {
          cartId,
        },
        forceOffline: isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
      transformResponse: (res: { data: iCart }) => res.data,
    }),
    addCartDiscount: builder.mutation<
      CartAPIResponse,
      WithOfflineFlag<CartDiscountAPIRequest>
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.CART_DISCOUNT,
        data: omit(payload, "isOffline"),
        forceOffline: payload.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
      invalidatesTags: ["Cart"],
    }),
    updateCartDiscount: builder.mutation<
      CartAPIResponse,
      WithOfflineFlag<UpdateCartDIscountAPIRequest>
    >({
      query: ({ cartId, discountId, ...rest }) => ({
        method: "PATCH",
        url: Endpoints.CART_DISCOUNT_BY_ID,
        data: omit(rest, "isOffline"),
        routeParams: {
          cartId: cartId,
          discountId: discountId,
        },
        forceOffline: rest.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
      invalidatesTags: ["Cart"],
    }),
    deleteCartDiscount: builder.mutation<
      CartAPIResponse,
      WithOfflineFlag<DeleteCartDiscountRequest>
    >({
      query: ({ cartId, discountId, isOffline }) => ({
        method: "DELETE",
        url: Endpoints.CART_DISCOUNT_BY_ID,
        routeParams: {
          discountId: discountId,
          cartId: cartId,
        },
        forceOffline: isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
      invalidatesTags: ["Cart"],
    }),
    saveCart: builder.mutation<iCart, WithOfflineFlag<CartSaveAPIRequest>>({
      query: ({ data, isOffline }) => ({
        method: "POST",
        url: Endpoints.SAVE_CART,
        data,
        forceOffline: isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
      transformResponse: (res: { data: iCart }) => res.data,
      invalidatesTags: (_, __, { shouldInvalidate }) => {
        if (shouldInvalidate) {
          return ["Cart"];
        }
        return [];
      },
    }),
    cancelCartBulk: builder.mutation<
      CartCancelAPIResponse,
      WithOfflineFlag<CartCancelBulkAPIRequest>
    >({
      query: (payload) => ({
        method: "PATCH",
        url: Endpoints.CANCEL_CART,
        data: {
          id: payload.cartIds,
          storeId: payload.storeId,
        },
        forceOffline: payload.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
      invalidatesTags: (_, err, { shouldInvalidate = true }) => {
        if (shouldInvalidate && !err) {
          return ["Cart"];
        }
        return [];
      },
    }),
    addItemToCart: builder.mutation<
      CartAPIResponse,
      WithOfflineFlag<CartAddItemAPIRequest>
    >({
      query: (data) => ({
        method: "PUT",
        url: Endpoints.ADD_ITEM_TO_CART,
        data: {
          storeId: data.storeId,
          cartId: data.cartId,
          inventoryId: data.inventoryId,
          quantity: data.quantity,
        },
        forceOffline: data.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    clearItemFromCart: builder.mutation<
      CartAPIResponse,
      WithOfflineFlag<CartClearItemAPIRequest>
    >({
      query: ({ cartId, inventoryId, isOffline }) => ({
        method: "DELETE",
        url: Endpoints.CART_ITEM,
        routeParams: {
          cartId,
          inventoryId: inventoryId.toString(),
        },
        forceOffline: isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    addCustomItemToCart: builder.mutation<
      CartAPIResponse,
      WithOfflineFlag<CartAddCustomItemAPIRequest>
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.ADD_CUSTOM_ITEM_TO_CART,
        data: omit(payload, "isOffline"),
        forceOffline: payload.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    updateCustomItemInCart: builder.mutation<
      CartAPIResponse,
      WithOfflineFlag<CartUpdateCustomItemAPIRequest>
    >({
      query: ({ customItemId, cartId, ...data }) => ({
        method: "PATCH",
        url: Endpoints.CART_CUSTOM_ITEM,
        routeParams: { cartId, customItemId },
        data: omit(data, "isOffline"),
        forceOffline: data.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    clearCustomItemFromCart: builder.mutation<
      CartAPIResponse,
      WithOfflineFlag<CartClearCustomItemAPIRequest>
    >({
      query: (payload) => ({
        method: "DELETE",
        url: Endpoints.CART_CUSTOM_ITEM,
        routeParams: omit(payload, "isOffline"),
        forceOffline: payload.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    checkoutCart: builder.mutation<
      CartCheckoutAPIResponse,
      WithOfflineFlag<CartCheckoutAPIRequest>
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.CHECKOUT_CART,
        data: omit(payload, "isOffline"),
        forceOffline: payload.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    loadCart: builder.mutation<
      CartAPIResponse,
      WithOfflineFlag<CartActivateAPIRequest>
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.ACTIVATE_CART,
        data: omit(payload, "isOffline"),
        forceOffline: payload.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    getSavedCarts: builder.query<
      CartSavedListAPIResponse,
      WithOfflineFlag<CartSavedListAPIRequest>
    >({
      query: (data) => ({
        method: "GET",
        url: Endpoints.CART,
        queryParams: data,
        forceOffline: data.isOffline,
      }),
      extraOptions: {
        withOffline: true,
      },
      providesTags: ["Cart"],
    }),
    getOfflineSavedCarts: builder.query<
      CartSavedListAPIResponse,
      CartSavedListAPIRequest & { isOffline?: boolean }
    >({
      query: (data) => ({
        method: "GET",
        url: Endpoints.CART,
        queryParams: data,
        forceOffline: true,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    getOnlineSavedCarts: builder.query<
      CartSavedListAPIResponse,
      CartSavedListAPIRequest & { isOffline?: boolean }
    >({
      query: (data) => ({
        method: "GET",
        url: Endpoints.CART,
        queryParams: data,
        forceOffline: true,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    getLocalCarts: builder.query<
      GetLocalCartAPIResponse,
      GetLocalCartAPIRequest
    >({
      query: (data) => ({
        method: "GET",
        url: Endpoints.GET_LOCAL_CART,
        forceOffline: true,
        queryParams: data,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    createDiscountItem: builder.mutation<
      CartAPIResponse,
      CreateDiscountItemAPIRequest
    >({
      query: ({ payload, routeParams }) => ({
        method: "post",
        url: Endpoints.CREATE_DISCOUNT_ITEM,
        data: payload,
        routeParams,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    updateDiscountItem: builder.mutation<
      CartAPIResponse,
      UpdateDiscountItemAPIRequest
    >({
      query: ({ payload, routeParams }) => ({
        method: "PATCH",
        url: Endpoints.MUTATE_DISCOUNT_ITEM,
        routeParams,
        data: payload,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    deleteDiscountItem: builder.mutation<
      CartAPIResponse,
      DeleteDiscountItemAPIRequest
    >({
      query: (params) => ({
        method: "DELETE",
        url: Endpoints.MUTATE_DISCOUNT_ITEM,
        routeParams: params,
      }),
      extraOptions: {
        withOffline: true,
      },
    }),
    createBillDownloadUrl: builder.mutation<
      CreateBillDownloadUrlAPIResponse,
      CreateBillDownloadUrlAPIRequest
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.CREATE_BILL_DOWNLOAD_URL,
        data: payload,
      }),
    }),
    sendBillEmail: builder.mutation<
      SendBillEmailAPIResponse,
      SendBillEmailAPIRequest
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.SEND_BILL_EMAIL,
        data: payload,
      }),
    }),
    getBillDownloadUrl: builder.query<
      GetBillDownloadUrlAPIResponse,
      GetBillDownloadUrlAPIRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_BILL_DOWNLOAD_URL,
        routeParams: {
          shortCode: args.shortCode,
        },
      }),
    }),
  }),
});

export const {
  useCreateCartMutation,
  useGetCartByIdQuery,
  useGetCartDetailByIdMutation,
  useAddCartDiscountMutation,
  useDeleteCartDiscountMutation,
  useSaveCartMutation,
  useCancelCartBulkMutation,
  useAddItemToCartMutation,
  useClearItemFromCartMutation,
  useClearCustomItemFromCartMutation,
  useAddCustomItemToCartMutation,
  useUpdateCustomItemInCartMutation,
  useCheckoutCartMutation,
  useUpdateCartDiscountMutation,
  useLoadCartMutation,
  useLazyGetSavedCartsQuery,
  useLazyGetOfflineSavedCartsQuery,
  useLazyGetOnlineSavedCartsQuery,
  endpoints: CartEndpoints,
  useLazyGetLocalCartsQuery,
  useLazyGetCartByIdQuery,
  useCreateDiscountItemMutation,
  useUpdateDiscountItemMutation,
  useDeleteDiscountItemMutation,
  useSendBillEmailMutation,
  useCreateBillDownloadUrlMutation,
  useGetBillDownloadUrlQuery,
  useLazyGetBillDownloadUrlQuery,
} = CartApi;

export default CartApi;
