import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { axiosBaseQuery, Endpoints } from "../../common/api";
import {
  GetInventoryHistoryMovementListResponse,
  GetInventoryHistoryRequest,
  GetInventoryHistoryResponse,
} from "./models";

const InventoryHistoryApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "inventory-history-rtk",
  endpoints: (builder) => ({
    getInventoryHistory: builder.query<
      GetInventoryHistoryResponse,
      GetInventoryHistoryRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.INVENTORY_HISTORY,
        queryParams: args,
      }),
    }),
    getInventoryHistoryMovementList: builder.query<
      GetInventoryHistoryMovementListResponse,
      void
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.INVENTORY_HISTORY_MOVEMENT_LIST,
        queryParams: args,
      }),
    }),
  }),
});

export const {
  useLazyGetInventoryHistoryQuery,
  useGetInventoryHistoryMovementListQuery,
} = InventoryHistoryApi;

export default InventoryHistoryApi;
