export enum actionTypes {
  // Article
  ARTICLE_ADD = "ARTICLE_ADD",
  ARTICLE_REMOVE = "ARTICLE_REMOVE",
  // Plain Axios API Example : Todo
  TODO_ADD = "TODO_ADD",
  TODO_SET = "TODO_SET",
  TODO_DELETE = "TODO_DELETE",
  TODO_LOADING = "TODO_LOADING",
}
