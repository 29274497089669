/* eslint-disable indent */
import { createApi } from "@reduxjs/toolkit/query/react";
import apiSauceBaseQuery from "../common/api/apiSauceBaseQuery";
import { Endpoints } from "../common/api/endpoints";
import {
  GetSupplierAPIResponse,
  GetSupplierAPIRequest,
  UpsertSupplierAPIResponse,
  UpsertSupplierAPIRequest,
  GetSupplierDetailRequest,
  GetSupplierDetailResponse,
  DeleteSupplierAPIRequest,
  DeleteSupplierAPIResponse,
} from "./types";
import { iApiResponse } from "../common/models";
import omit from "lodash/omit";

const SupplierApi = createApi({
  reducerPath: "supplierRtkQuery",
  baseQuery: apiSauceBaseQuery(),
  tagTypes: ["Supplier"],
  endpoints: (builder) => ({
    getSupplier: builder.query<GetSupplierAPIResponse, GetSupplierAPIRequest>({
      query: (args) => ({
        method: "get",
        url: Endpoints.GET_CONSIGNOR,
        queryParams: args,
      }),
      providesTags: ["Supplier"],
    }),
    createSupplier: builder.mutation<
      UpsertSupplierAPIResponse,
      UpsertSupplierAPIRequest
    >({
      query: (args) => ({
        method: "post",
        url: Endpoints.CREATE_CONSIGNOR,
        data: omit(args, "id"),
      }),
      transformResponse: (resp: iApiResponse<UpsertSupplierAPIResponse>) =>
        resp.data,
      invalidatesTags: ["Supplier"],
    }),
    upsertSupplier: builder.mutation<
      UpsertSupplierAPIResponse,
      UpsertSupplierAPIRequest
    >({
      query: (args) => ({
        method: "PATCH",
        url: Endpoints.UPDATE_CONSIGNOR,
        routeParams: {
          consignorId: String(args.id),
        },
        data: omit(args, "id"),
      }),
      transformResponse: (resp: iApiResponse<UpsertSupplierAPIResponse>) =>
        resp.data,
      invalidatesTags: ["Supplier"],
    }),
    deleteSupplier: builder.mutation<
      DeleteSupplierAPIResponse,
      DeleteSupplierAPIRequest
    >({
      query: (args) => ({
        method: "DELETE",
        url: Endpoints.DELETE_CONSIGNOR,
        routeParams: {
          consignorId: String(args.id),
        },
        queryParams: {
          storeId: args.storeId,
        },
      }),
      invalidatesTags: ["Supplier"],
    }),
    getSupplierDetail: builder.mutation<
      GetSupplierDetailResponse,
      GetSupplierDetailRequest
    >({
      query: (args) => ({
        method: "GET",
        url: Endpoints.GET_CONSIGNOR_BY_ID,
        routeParams: {
          consignorId: String(args.id),
        },
        queryParams: {
          storeId: args.storeId,
        },
      }),
    }),
  }),
});

export const {
  useCreateSupplierMutation,
  useGetSupplierQuery,
  useLazyGetSupplierQuery,
  useUpsertSupplierMutation,
  useDeleteSupplierMutation,
  useGetSupplierDetailMutation,
} = SupplierApi;

export default SupplierApi;
