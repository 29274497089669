import { axiosBaseQuery, Endpoints } from "../../../common/api";
import { createApi } from "@reduxjs/toolkit/query/react";
import {
  iDeliveryFeasibilityAPIRequest,
  iDeliveryFeasibilityAPIResponse,
  iCreateDeliveryOrderAPIRequest,
  iDeliveryOrderAPIResponse,
  iGetDeliveryOrderAPIRequest,
  iUpdateDeliveryOrderAPIRequest,
  iGetDeliveryOrderReportAPIResponse,
  iGetDeliveryOrderReportAPIRequest,
  iGetDeliveryOrderManifestAPIResponse,
  iGetDeliveryOrderManifestAPIRequest,
  iGetDeliveryOrderListAPIRequest,
  iGetDeliveryOrderListAPIResponse,
  iGetDeliveryOrderDetailAPIResponse,
  iGetDeliveryOrderDetailAPIRequest,
} from "./models";

const deliveryOrderApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: "delivery-order-rtkq",
  tagTypes: ["DeliveryOrder"],
  endpoints: (builder) => ({
    createDeliveryOrder: builder.mutation<
      iDeliveryOrderAPIResponse,
      iCreateDeliveryOrderAPIRequest
    >({
      query: (payload) => ({
        method: "POST",
        url: Endpoints.DELIVERY,
        data: payload,
      }),
      invalidatesTags: ["DeliveryOrder"],
    }),
    updateDeliveryOrder: builder.mutation<
      iDeliveryOrderAPIResponse,
      iUpdateDeliveryOrderAPIRequest
    >({
      query: (payload) => ({
        method: "PATCH",
        url: Endpoints.DELIVERY,
        data: payload,
      }),
      invalidatesTags: ["DeliveryOrder"],
    }),
    getDeliveryOrderReport: builder.mutation<
      iGetDeliveryOrderReportAPIResponse,
      iGetDeliveryOrderReportAPIRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.DELIVERY_ORDER_REPORT,
        queryParams: payload,
      }),
    }),
    getDeliveryOrderManifest: builder.mutation<
      iGetDeliveryOrderManifestAPIResponse,
      iGetDeliveryOrderManifestAPIRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.DELIVERY_ORDER_MANIFEST,
        queryParams: payload,
      }),
    }),
    getDeliveryOrderList: builder.query<
      iGetDeliveryOrderListAPIResponse,
      iGetDeliveryOrderListAPIRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.GET_REPORT_DELIVERY_ORDER_LIST,
        queryParams: payload,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ deliveryId }) => ({
                type: "DeliveryOrder" as const,
                id: deliveryId,
              })),
              "DeliveryOrder",
            ]
          : ["DeliveryOrder"],
    }),
    getDeliveryOrderDetail: builder.query<
      iGetDeliveryOrderDetailAPIResponse,
      iGetDeliveryOrderDetailAPIRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.DELIVERY,
        queryParams: payload,
      }),
      providesTags: (_res, _err, args) => [
        { type: "DeliveryOrder" as const, id: args.deliveryId },
      ],
    }),
    getDeliveryOrderFeasibility: builder.query<
      iDeliveryFeasibilityAPIResponse,
      iDeliveryFeasibilityAPIRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.DELIVERY_FEASIBLITY,
        queryParams: payload,
      }),
    }),
    getDeliveryOrder: builder.query<
      iDeliveryOrderAPIResponse,
      iGetDeliveryOrderAPIRequest
    >({
      query: (payload) => ({
        method: "GET",
        url: Endpoints.DELIVERY,
        queryParams: payload,
      }),
    }),
  }),
});

export const {
  useCreateDeliveryOrderMutation,
  useUpdateDeliveryOrderMutation,
  useGetDeliveryOrderReportMutation,
  useGetDeliveryOrderManifestMutation,
  useGetDeliveryOrderListQuery,
  useGetDeliveryOrderDetailQuery,
  useLazyGetDeliveryOrderFeasibilityQuery,
  useLazyGetDeliveryOrderQuery,
} = deliveryOrderApi;

export default deliveryOrderApi;
